import { useEffect, useState, useRef, useContext } from 'react';
import createStore from 'ctx-provider';
import { cloneDeep, isEqual } from 'lodash';
import authenticationCtx from 'context/authentication';
import exchangeCtx from 'context/exchange';
import useAlert from 'hooks/useAlert';
import { getTradesHistory, getClosedPositions, getOpenOrders, getPositions } from 'api/trading-api';
import { zoneDate } from 'lib/zone-date';
import { later } from 'lib/timers';

const REFRESH_TIME_MS = 5 * 1000;

const VALID_TRADETYPES = {
	1: true, // limit or market order
	6: true, // liquidation
};

const uniformTradeHistory = (exchange, data) => {
	let newData;
	if (exchange === 'dydx') {
		newData = data;
		data.forEach(row => {
			row.tradeType = row.type.toLowerCase();
			row.createdAt = new Date(row.updatedAt).getTime();
			row.execPriceRp = row.price;
			row.orderQtyRq = row.size;
			row.execQtyRq = row.totalFilled;
			row.execValueRv = row.execQtyRq * row.execPriceRp;
			row.isLongTrade = row.side === 'BUY';
			row.isCloseTrade = false;
		});
	} else if (exchange === 'phemex') {
		newData = data.filter(trade => VALID_TRADETYPES[trade.tradeType]);
	}
	return cloneDeep(newData);
};

const uniformOpenPositions = (exchange, data) => {
	let newData;
	if (exchange === 'dydx') {
		newData = data;
		data.forEach(row => {
			row.transactTimeNs = new Date(row.updatedAt).getTime() * 1000000;
			row.orderQtyRq = row.size;
			row.priceRp = row.price;
			row.orderType = row.side === 'BUY' ? 'Long' : 'Short';
			row.leavesQtyRq = row.size - row.totalFilled;
			row.leavesValueRv = row.leavesQtyRq * row.priceRp;
			row.expires = new Date(row.goodTilBlockTime);
		});
	} else if (exchange === 'phemex') {
		newData = data;
	}
	return cloneDeep(newData);
};

const uniformClosedPositions = (exchange, data) => {
	let newData;
	if (exchange === 'dydx') {
		newData = data;
		data.forEach(row => {
			row.openedTimeNs = new Date(row.createdAt).getTime();
			row.updatedTimeNs = new Date(row.closedAt).getTime();
			row.openPrice = row.entryPrice;
			row.closePrice = row.exitPrice || 0;
			row.closedSizeRq = Math.abs(row.maxSize || 0);
			row.size = Math.abs(row.size || 0);
			row.ownValue = row.size && row.leverage && row.entryPrice ? (row.size * row.entryPrice) / row.leverage : 0;
			row.exchangeFeeRv = row.fee; // TODO: we need to calculate
			row.fundingFeeRv = 0; // TODO: we need to calculate
			row.realizedPnlRv = row.realizedPnl;
			row.roi = row.roi / 100; // TODO: we need to calculate the profit
			row.exitsignal = ''; // TODO: we need set this by the api // f.e. 'stoploss';
		});
	} else if (exchange === 'phemex') {
		newData = data;
	}
	return cloneDeep(newData);
};

const uniformPositions = (exchange, data) => {
	let newData;
	if (exchange === 'dydx') {
		newData = data;
		data.forEach(row => {
			row.openedTimeNs = new Date(row.createdAt).getTime();
			row.updatedTimeNs = new Date(row.closedAt).getTime();
			row.openPrice = row.entryPrice;
			row.closePrice = row.exitPrice || 0;
			row.closedSizeRq = Math.abs(row.maxSize || 0);
			row.size = Math.abs(row.size || 0);
			row.ownValue = row.size && row.leverage && row.entryPrice ? (row.size * row.entryPrice) / row.leverage : 0;
			row.exchangeFeeRv = row.fee; // TODO: we need to calculate
			row.fundingFeeRv = 0; // TODO: we need to calculate
			row.realizedPnlRv = row.realizedPnl;
			row.roi = row.roi; // TODO: we need to calculate the profit
			row.exitsignal = ''; // TODO: we need set this by the api // f.e. 'stoploss';
		});
	} else if (exchange === 'phemex') {
		newData = data;
		data.forEach(position => {
			position.dateOpened = zoneDate(position.transactTimeNs / 1000000);
			position.leverage = parseFloat(position.leverageRr);
			position.ownValue = parseFloat(position.usedBalanceRv);
			position.entryPositionValue = position.leverage * position.ownValue;
			position.entryPrice = parseFloat(position.avgEntryPriceRp);
			position.currentPrice = parseFloat(position.markPriceRp);
			position.currentSize = parseFloat(position.size);
			position.liquidationPrice = parseFloat(position.liquidationPriceRp);
			position.liquidationLevel =
				100 *
				Math.abs((position.entryPrice - position.currentPrice) / (position.entryPrice - position.liquidationPrice));
			position.currentTradeCost = parseFloat(position.curTermRealisedPnlRv);
			position.currentPositionValueWithoutFeeCorrection =
				(position.entryPositionValue * position.currentPrice) / position.entryPrice;
			position.currentPositionValue = position.currentSize * position.currentPrice;
			position.feeCorrection = position.currentPositionValueWithoutFeeCorrection - position.currentPositionValue;

			if (position.isLong) {
				position.profit =
					position.currentPositionValue -
					position.entryPositionValue +
					position.feeCorrection +
					2 * position.currentTradeCost;
			} else {
				position.profit =
					position.entryPositionValue -
					position.currentPositionValue -
					position.feeCorrection +
					2 * position.currentTradeCost;
			}
		});
	}
	return newData;
};

const useTrades = () => {
	// const { exchange } = useContext(exchangeCtx);
	const alert = useAlert();
	const { isLoggedIn } = useContext(authenticationCtx);
	const { exchange } = useContext(exchangeCtx);

	const [subscriptionTradesHistory, setSubscriptionTradesHistory] = useState(true);
	const [subscriptionOpenOrders, setSubscriptionOpenOrders] = useState(false);
	const [subscriptionClosedPositions, setSubscriptionClosedPositions] = useState(false);
	const [subscriptionPositions, setSubscriptionPositions] = useState(false);

	const [tradesHistory, setTradesHistory] = useState([]);
	const [openOrders, setOpenOrders] = useState([]);
	const [closedPositions, setClosedPositions] = useState([]);
	const [positions, setPositions] = useState([]);
	const [positionsLoaded, setPositionsLoaded] = useState(false);
	const exchangeRef = useRef(exchange);
	const timeoutTradesHistoryId = useRef();
	const timeoutOpenOrdersId = useRef();
	const timeoutClosedPositionsId = useRef();
	const timeoutPositionsId = useRef();
	const positionsLoadedRef = useRef(false);
	const prevPositionsRef = useRef();
	const prevTradesHistoryRef = useRef();
	const prevOpenOrdersRef = useRef();
	const prevClosedPositionsRef = useRef();
	const firstTimePositionsRequestRef = useRef(true);

	const subscribeTradesHistory = () => {
		prevTradesHistoryRef.current = null;
		setSubscriptionTradesHistory(true);
	};

	const subscribeOpenOrders = () => {
		prevOpenOrdersRef.current = null;
		setSubscriptionOpenOrders(true);
	};

	const subscribeClosedPositions = () => {
		prevClosedPositionsRef.current = null;
		setSubscriptionClosedPositions(true);
	};

	const subscribePositions = () => {
		prevPositionsRef.current = null;
		setSubscriptionPositions(true);
	};

	const unsubscribeTradesHistory = () => {
		setSubscriptionTradesHistory(false);
	};

	const unsubscribeOpenOrders = () => {
		setSubscriptionOpenOrders(false);
	};

	const unsubscribeClosedPositions = () => {
		setSubscriptionClosedPositions(false);
	};

	const unsubscribePositions = () => {
		setSubscriptionPositions(false);
	};

	const cancelTimeoutTradesHistory = () => {
		if (timeoutTradesHistoryId.current) {
			timeoutTradesHistoryId.current.cancel();
		}
	};

	const cancelTimeoutOpenOrders = () => {
		if (timeoutOpenOrdersId.current) {
			timeoutOpenOrdersId.current.cancel();
		}
	};

	const cancelTimeoutClosedPositions = () => {
		if (timeoutClosedPositionsId.current) {
			timeoutClosedPositionsId.current.cancel();
		}
	};

	const cancelTimeoutPositions = () => {
		if (timeoutPositionsId.current) {
			timeoutPositionsId.current.cancel();
		}
	};

	const loadTradesHistory = async () => {
		if (isLoggedIn && exchangeRef.current) {
			try {
				const exchange = exchangeRef.current.name;
				const network = exchangeRef.current.network;
				let symbol, limit, offset;
				let data = await getTradesHistory({ exchange, network, symbol, limit, offset });
				// make sure we still have the same exchange and network as what is used before the async call
				if (exchange === exchangeRef.current.name && network === exchangeRef.current.network && data) {
					const newData = uniformTradeHistory(exchange, data);
					if (!prevTradesHistoryRef.current || !isEqual(newData, prevTradesHistoryRef.current)) {
						prevTradesHistoryRef.current = cloneDeep(newData);
						setTradesHistory(newData);
					}
				}
			} catch (err) {
				const message = err.response?.data?.error || err.message || 'Server error';
				// send an alert, but prevent alerting multiple times, by marking this message with "match":
				alert(message, { noDupes: true, match: `${message}#error`, severity: 'error' });
			}
		}
		cancelTimeoutTradesHistory();
		timeoutTradesHistoryId.current = later(loadTradesHistory, REFRESH_TIME_MS);
	};

	const loadOpenOrders = async () => {
		if (isLoggedIn && exchangeRef.current) {
			try {
				const exchange = exchangeRef.current.name;
				const network = exchangeRef.current.network;
				let data = await getOpenOrders({ exchange, network });
				// make sure we still have the same exchange and network as what is used before the async call
				if (exchange === exchangeRef.current.name && network === exchangeRef.current.network && data) {
					const newData = uniformOpenPositions(exchange, data);
					if (!prevOpenOrdersRef.current || !isEqual(newData, prevOpenOrdersRef.current)) {
						prevOpenOrdersRef.current = cloneDeep(newData);
						setOpenOrders(newData);
						// setOpenOrders(data);
					}
				}
			} catch (err) {
				const message = err.response?.data?.error || err.message || 'Server error';
				// send an alert, but prevent alerting multiple times, by marking this message with "match":
				alert(message, { noDupes: true, match: `${message}#error`, severity: 'error' });
			}
		}
		cancelTimeoutOpenOrders();
		timeoutOpenOrdersId.current = later(loadOpenOrders, REFRESH_TIME_MS);
	};

	const loadClosedPositions = async () => {
		if (isLoggedIn && exchangeRef.current) {
			try {
				const exchange = exchangeRef.current.name;
				const network = exchangeRef.current.network;
				let symbol, limit, offset;
				let data = await getClosedPositions({ exchange, network, symbol, limit, offset });
				// make sure we still have the same exchange and network as what is used before the async call
				if (exchange === exchangeRef.current.name && network === exchangeRef.current.network && data) {
					const newData = uniformClosedPositions(exchange, data);
					if (!prevClosedPositionsRef.current || !isEqual(newData, prevClosedPositionsRef.current)) {
						prevClosedPositionsRef.current = cloneDeep(newData);
						setClosedPositions(newData);
					}
				}
			} catch (err) {
				const message = err.response?.data?.error || err.message || 'Server error';
				// send an alert, but prevent alerting multiple times, by marking this message with "match":
				alert(message, { noDupes: true, match: `${message}#error`, severity: 'error' });
			}
		}
		cancelTimeoutClosedPositions();
		timeoutClosedPositionsId.current = later(loadClosedPositions, REFRESH_TIME_MS);
	};

	const loadPositions = async options => {
		let data;
		const requestfromExchange = !!options?.requestfromExchange;
		const onlyFromDatabase = !requestfromExchange;
		if (isLoggedIn && exchangeRef.current) {
			try {
				const exchange = exchangeRef.current.name;
				const network = exchangeRef.current.network;
				data = await getPositions({ exchange, network, onlyFromDatabase, force: requestfromExchange });

				// correct missing data from Phemex:
				data.forEach(item => (item.isOpen = true));

				// make sure we still have the same exchange and network as what is used before the async call
				if (exchange === exchangeRef.current.name && network === exchangeRef.current.network && data) {
					const newPositions = uniformPositions(exchange, data);
					if (!prevPositionsRef.current || !isEqual(newPositions, prevPositionsRef.current)) {
						prevPositionsRef.current = cloneDeep(newPositions);
						setPositions(newPositions);
					}
					if (!positionsLoadedRef.current) {
						setPositionsLoaded(true);
					}
				}
			} catch (err) {
				const message = err.response?.data?.error || err.message || 'Server error';
				// send an alert, but prevent alerting multiple times, by marking this message with "match":
				alert(message, { noDupes: true, match: `${message}#error`, severity: 'error' });
			}

			// when first time and empty result, we will ask a list not from cache
			if ((!data || data.length === 0) && !requestfromExchange && firstTimePositionsRequestRef.current) {
				firstTimePositionsRequestRef.current = false;
				loadPositions({ requestfromExchange: true });
			}
		}
		cancelTimeoutPositions();
		timeoutPositionsId.current = later(loadPositions, REFRESH_TIME_MS);
	};

	useEffect(() => {
		return () => {
			cancelTimeoutTradesHistory();
			cancelTimeoutOpenOrders();
			cancelTimeoutClosedPositions();
			cancelTimeoutPositions();
		};
	}, []);

	useEffect(() => {
		positionsLoadedRef.current = positionsLoaded;
	}, [positionsLoaded]);

	useEffect(() => {
		exchangeRef.current = exchange;
		if (isLoggedIn) {
			if (subscriptionTradesHistory) {
				cancelTimeoutTradesHistory();
				setTradesHistory([]);
				loadTradesHistory();
			}
			if (subscriptionOpenOrders) {
				cancelTimeoutOpenOrders();
				setOpenOrders([]);
				loadOpenOrders();
			}
			if (subscriptionClosedPositions) {
				cancelTimeoutClosedPositions();
				setClosedPositions([]);
				loadClosedPositions();
			}
			if (subscriptionPositions) {
				cancelTimeoutPositions();
				setPositionsLoaded(false);
				setPositions([]);
				loadPositions({ requestfromExchange: true });
			}
		}
	}, [exchange]);

	useEffect(() => {
		if (isLoggedIn && exchangeRef.current) {
			if (subscriptionTradesHistory) {
				cancelTimeoutTradesHistory();
				loadTradesHistory();
			}
			if (subscriptionOpenOrders) {
				cancelTimeoutOpenOrders();
				loadOpenOrders();
			}
			if (subscriptionClosedPositions) {
				cancelTimeoutClosedPositions();
				loadClosedPositions();
			}
			if (subscriptionPositions) {
				cancelTimeoutPositions();
				loadPositions({ requestfromExchange: true });
			}
		} else {
			cancelTimeoutTradesHistory();
			cancelTimeoutOpenOrders();
			cancelTimeoutClosedPositions();
			cancelTimeoutPositions();
			setPositionsLoaded(false);
			setTradesHistory([]);
			setOpenOrders([]);
			setClosedPositions([]);
			setPositions([]);
		}
	}, [isLoggedIn]);

	useEffect(() => {
		if (isLoggedIn && exchangeRef.current && subscriptionTradesHistory) {
			loadTradesHistory();
		} else {
			cancelTimeoutTradesHistory();
			setTradesHistory([]);
		}
	}, [isLoggedIn, subscriptionTradesHistory]);

	useEffect(() => {
		if (isLoggedIn && exchangeRef.current && subscriptionOpenOrders) {
			loadOpenOrders();
		} else {
			cancelTimeoutOpenOrders();
			setOpenOrders([]);
		}
	}, [isLoggedIn, subscriptionOpenOrders]);

	useEffect(() => {
		if (isLoggedIn && exchangeRef.current && subscriptionClosedPositions) {
			loadClosedPositions();
		} else {
			cancelTimeoutClosedPositions();
			setClosedPositions([]);
		}
	}, [isLoggedIn, subscriptionClosedPositions]);

	useEffect(() => {
		if (isLoggedIn && exchangeRef.current && subscriptionPositions) {
			loadPositions({ requestfromExchange: true });
		} else {
			cancelTimeoutPositions();
			setPositions([]);
		}
	}, [isLoggedIn, subscriptionPositions]);

	return {
		tradesHistory,
		openOrders,
		closedPositions,
		positions,
		positionsLoaded,

		subscribeTradesHistory,
		subscribeOpenOrders,
		subscribeClosedPositions,
		subscribePositions,

		unsubscribeTradesHistory,
		unsubscribeOpenOrders,
		unsubscribeClosedPositions,
		unsubscribePositions,
	};
};

const store = createStore(useTrades);

export const { Provider } = store;
export default store.ctx;
