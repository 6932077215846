import React from 'react';
import PropTypes from 'prop-types';
import AlertDialog from 'components/common/dialogs/AlertDialog';

const AlertModal = props => {
	const { classNameHeader, onClose, title } = props;

	let header;
	if (title || onClose) {
		header = <div className={classNameHeader}>{title}</div>;
	}
	return (
		<div>
			{header}
			<AlertDialog onOk={onClose} {...props} />
		</div>
	);
};

AlertModal.defaultProps = {
	className:
		'fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 text-2xl flex items-center px-8 py-12 bg-blue-500',
	severity: 'success',
};

AlertModal.propTypes = {
	action: PropTypes.object,
	onClose: PropTypes.func.isRequired,
	severity: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
	message: PropTypes.string.isRequired,
};

export default AlertModal;
