const NOT_SO_GOOD = 0.95;
const BAD = 0.9;
const SUPERBAD = 0.8;

const MATURE = 3;
const SUPERMATURE = 8;
const MAX_INSPECTED_ITEMS = 30; // to prevent the calculation of "bad" to have the same impact on a huge nr of items as on a small nr
const REALISTIC_GOOD_TRADES_FRACTION = 0.95; // in case we get a better result that 1/20 bad -> assume this will not happen and take 1/20
const CORRECTION_SAFE_GOOD_TRADES = 0.9; // assume that the results may become worse

const _getTradesResultScore = tradesresult => {
	if (!tradesresult) {
		return { score: 1, good: 0, bad: 0 };
	}

	const tradesResultObject = getTradesResult(tradesresult);
	const good = tradesResultObject.good;
	const bad = tradesResultObject.bad + tradesResultObject.stoploss;
	const ratio = good / tradesresult.length;

	let score;
	const tradesResultObjectLastTrades = getTradesResult(tradesresult, 10);
	if (tradesResultObjectLastTrades.stoploss > 0) {
		score = 0; // red
	} else if (ratio <= SUPERBAD) {
		score = 0; // red
	} else if (ratio <= BAD) {
		score = 1; // orange
	} else if (ratio <= NOT_SO_GOOD) {
		score = 2; // yellow
	} else {
		score = 3; // green
	}

	return { score, good, bad };
};

const getTradesResult = (tradesresult, minInspectItems = MAX_INSPECTED_ITEMS) => {
	if (!tradesresult) {
		return {
			good: 0,
			bad: 0,
			stoploss: 0,
			trades: 0,
			positiveTradeProfit: 0,
			negativeTradeProfit: 0,
		};
	}

	let good = 0;
	let bad = 0;
	let stoploss = 0;
	let totalPositiveTradeProfit = 0;
	let totalNegativeTradeProfit = 0;

	tradesresult.some((result, i) => {
		const profitable = result.profitable;
		const exitprice = parseFloat(result.exitprice);
		const entryprice = parseFloat(result.entryprice);

		let profit;
		if (entryprice) {
			if (result.islong) {
				profit = (100 * (exitprice - entryprice)) / entryprice;
			} else {
				profit = (100 * (entryprice - exitprice)) / entryprice;
			}
		} else {
			profit = 0;
		}

		if (profitable) {
			good++;
			totalPositiveTradeProfit += profit;
		} else if (result.signal === 'stoploss') {
			stoploss++;
			totalNegativeTradeProfit += profit;
		} else {
			bad++;
			totalNegativeTradeProfit += profit;
		}
		return i === minInspectItems - 1;
	});

	const totalTrades = good + bad + stoploss;

	return {
		good,
		bad,
		stoploss,
		positiveTradeProfit: totalTrades > 0 ? totalPositiveTradeProfit / totalTrades : 0,
		negativeTradeProfit: totalTrades > 0 ? totalNegativeTradeProfit / totalTrades : 0,
		trades: Math.min(minInspectItems, tradesresult.length),
	};
};

const getTradesResultScore = tradesresult => {
	const strategyMaturity = getStrategyMaturity(tradesresult);
	const substract = 3 - strategyMaturity;
	let { score } = _getTradesResultScore(tradesresult);

	score = score - substract;

	return Math.max(score, 0);
};

const getStrategyMaturity = tradesresult => {
	if (!tradesresult || tradesresult.length < MATURE) {
		return 1;
	}
	if (tradesresult.length < SUPERMATURE) {
		return 2;
	}
	return 3;
};

const getKellyCriterion = tradesresult => {
	if (!tradesresult) {
		return 0;
	}

	// since tradesresult can come from the strategysimulation as wel as from the true past trades, we need to be able to handle both structures:
	const goodTrades = typeof tradesresult.goodTrades === 'number' ? tradesresult.goodTrades : tradesresult.good;
	const badTrades = typeof tradesresult.badTrades === 'number' ? tradesresult.badTrades : tradesresult.bad;
	const stoplossCount =
		typeof tradesresult.stoplossCount === 'number' ? tradesresult.stoplossCount : tradesresult.stoploss;

	const totalTrades = goodTrades + badTrades + stoplossCount;
	if (totalTrades === 0) {
		return 0;
	}

	let s = Math.min(goodTrades / totalTrades, REALISTIC_GOOD_TRADES_FRACTION); // fraction successrate
	const p = tradesresult.positiveTradeProfit; // profit successful trade in %
	const l = -tradesresult.negativeTradeProfit; // losses wrong trade in %

	if (p === 0) {
		return 0; // cannot divide by zero
	}

	s = CORRECTION_SAFE_GOOD_TRADES * s;

	const kellyCriterion = (s * (p + l) - l) / p;

	return Math.max(0, 100 * kellyCriterion);
};

const getMaxInspectedItems = () => MAX_INSPECTED_ITEMS;

const getInfo = tradesresult => {
	const { score, good, bad } = _getTradesResultScore(tradesresult);
	const maturity = getStrategyMaturity(tradesresult);
	const finalScore = Math.max(0, score + maturity - 3);
	const COLORS = {
		0: 'rgb(239, 68, 68)',
		1: 'rgb(217, 119, 6)',
		2: 'rgb(253, 224, 71)',
		3: 'rgb(22, 163, 74)',
	};
	const TEXT_COLORS = {
		0: 'white',
		1: 'white',
		2: 'black',
		3: 'white',
	};
	const COLORS_BETA = {
		1: 'rgb(217, 119, 6)',
		2: 'rgb(253, 224, 71)',
	};
	const TEXT_COLORS_BETA = {
		1: 'white',
		2: 'black',
	};
	const scoreColorStyle = `display: flex; align-items: center; justify-content: center; border-width: 1px; background-color: ${COLORS[finalScore]}; color: ${TEXT_COLORS[finalScore]}; border-radius: 9999px; width: 1.25rem; height: 1.25rem; text-align: center; font-weight: 300; font-size: 0.75rem; line-height: 1.2rem`;

	let beta = '';
	if (maturity < SUPERMATURE) {
		const betaStyle = `display: inline-flex; padding-left: 6px; padding-right: 6px; margin-left: 12px; align-items: center; justify-content: center; border-width: 1px; background-color: ${COLORS_BETA[maturity]}; color: ${TEXT_COLORS_BETA[maturity]}; border-radius: 1rem; rounded-full; height: 1.25rem; text-align: center; font-weight: 300; font-size: 0.75rem; line-height: 1.2rem`;
		beta = `<span style="${betaStyle}">beta</span>`;
	}

	return (
		`<span style="color: yellow; font-size: 120%"><u>Strategy Score</u>: <div style="display: inline-block"><div style="${scoreColorStyle}">${finalScore}</div></div></span><br><br>` +
		'<u>Colors</u><br>' +
		`<div style="display: inline-block; background-color: ${COLORS[0]}; padding: 4px; border-radius: 10%">0: red</div><br>` +
		`<div style="display: inline-block; background-color: ${COLORS[1]}; padding: 4px; border-radius: 10%">1: orange</div><br>` +
		`<div style="display: inline-block; background-color: ${COLORS[2]}; padding: 4px; border-radius: 10%; color: black">2: yellow</div><br>` +
		`<div style="display: inline-block; background-color: ${COLORS[3]}; padding: 4px; border-radius: 10%">3: green</div><br><br>` +
		'<u>Trade Result</u><br>' +
		`Good trades: ${good}<br>` +
		`Bad trades: ${bad}<br>` +
		`Score: <span style="color: yellow; font-weight: bold">${score}</span> out of 3<br><br>` +
		'<u>Strategy Maturity</u><br>' +
		`Number of trades: ${tradesresult.length}<br>` +
		`Score: ${maturity} out of 3<br>` +
		`Maturity_Weakness: <span style="color: yellow; font-weight: bold">${3 - maturity}</span>${beta}<br>` +
		`(Maturity_Weakness = 3 - Score)<br><br><br>` +
		'<u>Explanation</u><br><br>' +
		'Strategy Score = Trade_Result - Maturity_Weakness<br>' +
		`=><br>` +
		`<span style="color: yellow; font-weight: bold">${score} - ${3 - maturity} = ${finalScore}</span><br><br>` +
		`Trade result over the last ${MAX_INSPECTED_ITEMS} trades:<br>` +
		`Bad Trades >= ${SUPERBAD} --> score = 1<br>` +
		`Bad Trades >= ${BAD} --> score = 2<br>` +
		`Bad Trades < ${BAD} --> score = 3<br><br>` +
		`Number of Trades:<br>` +
		`Trades < ${MATURE} --> score = 1<br>` +
		`Trades < ${SUPERMATURE} --> score = 2<br>` +
		`Trades >= ${SUPERMATURE} --> score = 3`
	);
};

module.exports = {
	getTradesResultScore,
	getStrategyMaturity,
	getTradesResult,
	getKellyCriterion,
	getMaxInspectedItems,
	getInfo,
};
