import React, { useState, useContext, useRef } from 'react';
import authenticationCtx from 'context/authentication';

const LoginForm = props => {
	const [account, setAccount] = useState('');
	const [password, setPassword] = useState('');
	const { tryLogin, initialized } = useContext(authenticationCtx);
	const passwordRef = useRef();
	const sendbuttonRef = useRef();

	const formValid = !!account && !!password;

	const handleChangeEmail = e => {
		setAccount(e.target.value);
	};

	const handleChangePassword = e => {
		setPassword(e.target.value);
	};

	const handleKeyDownEmail = e => {
		if (e.keyCode === 13) {
			console.debug(passwordRef.current);
			passwordRef.current.focus();
		}
	};

	const handleKeyDownPassword = e => {
		if (e.keyCode === 13) {
			sendbuttonRef.current.click();
		}
	};

	const submitForm = async e => {
		e.preventDefault();
		if (formValid) {
			const success = await tryLogin(account, password);
			if (!success) {
				setPassword('');
			}
		}
	};

	return (
		<form className="container flex flex-col max-w-sm gap-4" onSubmit={submitForm}>
			<input
				type="text"
				onChange={handleChangeEmail}
				onKeyDown={handleKeyDownEmail}
				value={account}
				placeholder="email"
				className="form-input w-full rounded border border-indigo-300 bg-transparent px-4 py-3 text-white placeholder:text-white/25"
				autoFocus
			/>
			<input
				type="password"
				ref={passwordRef}
				onChange={handleChangePassword}
				onKeyDown={handleKeyDownPassword}
				value={password}
				placeholder="password"
				autocomplete="current-password"
				className="form-input w-full rounded border border-indigo-300 bg-transparent px-4 py-3 text-white placeholder:text-white/25"
			/>
			<button
				disabled={!formValid}
				ref={sendbuttonRef}
				className="rounded whitespace-nowrap text-center flex gap-x-3 items-center text-lg bg-yellow-400 text-black hover:bg-yellow-500 active:bg-yellow-400 justify-center px-3 py-2"
			>
				Send
			</button>
		</form>
	);
};

export default LoginForm;
