import { useState, useRef } from 'react';
import createStore from 'ctx-provider';

const useBackdrop = () => {
	const [backdrop, setBackdropFn] = useState(false);
	const countRef = useRef(0);

	const setBackdrop = value => {
		if (value) {
			countRef.current++;
		} else if (countRef.current > 0) {
			countRef.current--;
		}
		setBackdropFn(countRef.current > 0);
	};

	return {
		backdrop,
		setBackdrop,
	};
};

const store = createStore(useBackdrop);

export const { Provider } = store;
export default store.ctx;
