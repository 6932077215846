import React from 'react';
import PropTypes from 'prop-types';
import { CombinedProviders } from 'ctx-provider';
import { Provider as AccountProvider } from 'context/account';
import { Provider as AccountStrategiesProvider } from 'context/account-strategies';
import { Provider as AuthenticationProvider } from 'context/authentication';
import { Provider as ExchangeProvider } from 'context/exchange';
import { Provider as StrategiesProvider } from 'context/strategies';
import { Provider as StrategySimulationsProvider } from 'context/strategy-simulations';
import { Provider as TradesProvider } from 'context/trades';
import { Provider as TradeHistoryProvider } from 'context/tradehistory';
import { Provider as MarketdataProvider } from 'context/marketdata';
import { Provider as BalancesProvider } from 'context/balances';
import { Provider as FeedbackProvider } from 'context/feedback';
import { Provider as BackdropProvider } from 'context/backdrop';
import { Provider as AlertProvider } from 'context/alert';
import { Provider as ConfirmProvider } from 'context/confirm';

const GlobalContext = ({ children }) => {
	const providers = [
		BackdropProvider, // before other providers that use a backdrop
		FeedbackProvider,
		AccountProvider, // before AuthenticationProvider because AuthenticationProvider uses AccountProvider
		ExchangeProvider, // before any other provider that needs the exchange
		AccountStrategiesProvider,
		AuthenticationProvider, // before StrategiesProvider, BalancesProvider and TradesProvider
		StrategiesProvider,
		StrategySimulationsProvider,
		TradesProvider, // before BalancesProvider that needs the trades positions
		BalancesProvider,
		MarketdataProvider,
		TradeHistoryProvider,
		ConfirmProvider,
		AlertProvider,
	];

	return <CombinedProviders providers={providers}>{children}</CombinedProviders>;
};

GlobalContext.propTypes = {
	pageProps: PropTypes.object.isRequired,
	children: PropTypes.node.isRequired,
};

export default GlobalContext;
