import React from 'react';
import PropTypes from 'prop-types';

const OkButton = ({ classNameContainer, classNameButton, onOk }) => {
	return (
		<div className={classNameContainer}>
			<button className={classNameButton} onClick={onOk}>
				Ok
			</button>
		</div>
	);
};

OkButton.defaultProps = {
	classNameContainer: 'flex flex-col-reverse w-full sm:flex-row sm:w-auto',
	classNameButton: 'bg-blue-400 text-white sm:ml-2 sm:w-auto w-[90vw] px-4 py-2',
};

OkButton.propTypes = {
	classNameContainer: PropTypes.string,
	classNameButton: PropTypes.string,
	onOk: PropTypes.func.isRequired,
};

export default OkButton;
