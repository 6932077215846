import expandBnDecimals from '../../lib/expand-BN-decimals'; // LEAVE THIS AS A RELAIVE PATH! Otherwise "yarn build" will fail

export const LOGO_URL = '/images/svg/logo.svg';

// refresh times:
export const REFRESH_TIME_OPENORDERS_SECS = 30;
export const REFRESH_TIME_ACCOUNTDATA_SEC = 10;
export const REFRESH_TIME_ACCOUNTSTRATEGYDATA_SEC = 10;
export const REFRESH_TIME_STRATEGYSIMULATIONS_SEC = 5;
export const REFRESH_TIME_STRATEGYSIMULATORS_SEC = 5;
export const REFRESH_TIME_STRATEGIES_SEC = 10;
export const REFRESH_TIME_MARKETDATA_MIN = 10;

export const SIMULATION_RECORDS = 1000; // amount of records to be shown in the simulations results
export const ONE_HOUR_MS = 60 * 60 * 1000;
export const REFRESH_TIME_KRAKEN_MIN = 1; // mins
export const LOGOUT_AFTER_IDDLE = true;
export const LOGOUT_AFTER_IDDLE_MIN = 30;
export const ENVIRONMENT = process.env.GATSBY_ENV;
export const IS_PRODUCTION_ENV = ENVIRONMENT === 'production';
export const TRADING_API = IS_PRODUCTION_ENV ? 'https://tradingapp.itsa.io/api' : '/api';
export const PRICE_RANGE_VOLUME = 25;
export const MAX_LEVERAGE = 50; // Same as whatthe BOT uses -> NEVER go beyond 50 -> this value will be used as a safety net
export const CAN_UPDATE_ACTIVE_STRATEGY = !IS_PRODUCTION_ENV;
export const ACCEPT_STOPLOSS_LIMIT_ORDERS = false; // do NOT accept this: it will mess up the auto-cleanup limit orders process
export const MANUAL_ORDERS_ONLY_AS_MARKET = true; // because dydx will cancel stoploss and takeprofit from other types of orders, since they aren;t filled immediately

const DYDX_DERIVATION_PATH = `m/44'/637'/0'/0/0`;

// admin urls/pages which can only be accessed by users with role === 'admin'
export const ADMIN_PAGES = {
	'user-management': true,
};

export const INTERVALS = {
	1: '1min',
	5: '5min',
	15: '15min',
	30: '30min',
	60: '1hr',
	120: '2hr',
	240: '2hr',
	480: '4hr',
	1440: '1d',
};

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

export const TRADES_RESULT_SCORE_COLORS = {
	1: 'red-600',
	2: 'amber-600',
	3: 'green-600',
};

export const STRATEGY_MATURITY_COLORS = {
	1: 'amber-500',
	2: 'yellow-500',
	3: 'current',
};

export const BSC = 56;
export const BSC_TESTNET = 97;
export const ETHEREUM = 1;
export const AVALANCHE = 43114;
export const AVALANCHE_FUJI = 43113;
export const ARBITRUM = 42161;
export const ARBITRUM_TESTNET = 421611;
export const PHEMEX = 'phemex';

export const STRATEGY_ASSETS = [
	'BTC',
	'ETH',
	'SOL',
	'XRP',
	'LTC',
	'UNI',
	'ADA',
	'TON',
	'DOGE',
	'SHIB',
	'AVAX',
	'TRX',
	'DOT',
	'BCH',
	'BNB',
	'LINK',
	'ETC',
	'POL',
	'NEAR',
	'PEPE',
	'1INCH',
	'APT',
	// 'MNT', // NOT at this time: cannot be traded on phemex
	'ONDO',
	'NOT',
	'FIL',
	'ARB',
	'MEW',
	// 'XMR', // CANNOT be trades on Phemex
	'XLM',
	'IBM',
	'TESLA',
	'HEINEKEN',
	'HEINEKEN HOLDING',
	'ASML',
];

STRATEGY_ASSETS.sort();

// alphavantage does not serve intradaily candles for some assets:
export const NO_INTRADAY_CANDLES = {
	HEINEKEN: true,
	'HEINEKEN HOLDING': true,
};

export const STRATEGY_CANDLES_NUMBERS = [200, 500, 1000, 2000, 3000, 4000, 5000];
export const STRATEGY_LEVERAGES = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50];
export const LEVERAGE_LIST = [
	'1',
	'2',
	'3',
	'4',
	'5',
	'6',
	'7',
	'8',
	'9',
	'10',
	'10',
	'11',
	'12',
	'13',
	'14',
	'15',
	'16',
	'17',
	'18',
	'19',
	'20',
	'25',
	'30',
	'35',
	'40',
	'45',
	'50',
];

export const CANDLE_INTERVALS = [
	// 1, // '1m'
	5, // '5m'
	15, // '15m'
	60, // '1h'
	1440, // '1d'
];

// supported exchanges (make sure the propertyneames are lowercased):
export const EXCHANGES = {
	kraken: {
		displayName: 'Kraken',
		name: 'kraken',
		network: null,
		chainId: null,
		nativeToken: null,
		marginModels: ['cross'],
		leverageMin: 5,
		leverageMax: 5,
		baseToken: 'USDT',
		tradeTokens: ['BTC', 'ETH'],
		tradeEnabled: false,
	},
	phemex: {
		displayName: 'Phemex Contract',
		name: 'phemex',
		network: 'contract',
		leverageMin: 5,
		leverageMax: 5,
		baseToken: 'USDT',
		tradeTokens: [
			'BTC',
			'ETH',
			'SOL',
			'XRP',
			'LTC',
			'UNI',
			'ADA',
			'TON',
			'DOGE',
			'SHIB',
			'AVAX',
			'TRX',
			'DOT',
			'BCH',
			'BNB',
			'LINK',
			'ETC',
			'POL',
			'NEAR',
			'PEPE',
			'1INCH',
			'APT',
			// 'MNT', // CANNOT be trade on Phemex
			'ONDO',
			'NOT',
			'FIL',
			'ARB',
			// 'XMR', // CANNOT be trade on Phemex
			'XLM',
			'MEW',
		],
		tradeEnabled: true,
	},
	gmx_arbitrum: {
		displayName: 'GMX Arbitrum',
		name: 'gmx',
		network: 'arbitrum',
		chainId: ARBITRUM,
		nativeToken: 'ETH',
		marginModels: ['margin'],
		leverageMin: 1.1,
		leverageMax: 50,
		leverageDefault: 1.1,
		baseToken: 'USDC',
		tradeTokens: ['BTC', 'ETH'],
		tradeEnabled: false,
	},
	gmx_avalanche: {
		displayName: 'GMX Avalanche',
		name: 'gmx',
		network: 'avalanche',
		chainId: AVALANCHE,
		nativeToken: 'ETH',
		marginModels: ['margin'],
		leverageMin: 1.1,
		leverageMax: 50,
		leverageDefault: 2,
		baseToken: 'USDC',
		tradeTokens: ['BTC', 'ETH'],
		tradeEnabled: false,
	},
	kucoin: {
		displayName: 'Kucoin',
		name: 'kucoin',
		marginModels: ['cross'],
		leverageMin: 5,
		leverageMax: 5,
		baseToken: 'USDT',
		tradeTokens: ['BTC', 'ETH'],
		tradeEnabled: false,
	},
	dydx: {
		displayName: 'dYdX',
		name: 'dydx',
		network: 'mainnet',
		chainId: null,
		nativeToken: 'DYDX',
		marginModels: ['cross', 'isolated'],
		leverageMin: 1,
		leverageMax: 50,
		baseToken: 'USDC',
		tradeTokens: [
			'BTC',
			'ETH',
			'SOL',
			'XRP',
			'LTC',
			'UNI',
			'ADA',
			'TON',
			'DOGE',
			'SHIB',
			'AVAX',
			'TRX',
			'DOT',
			'BCH',
			'BNB',
			'LINK',
			'ETC',
			// 'POL',
			'NEAR',
			'PEPE',
			'1INCH',
			'APT',
			'MNT',
			'ONDO',
			'NOT',
			'FIL',
			'ARB',
			'XMR',
			'XLM',
			'MEW',
		],

		tradeEnabled: true,
	},
	'dydx-testnet': {
		displayName: 'dYdX testnet',
		name: 'dydx',
		network: 'testnet',
		chainId: null,
		nativeToken: 'DYDX',
		marginModels: ['cross', 'isolated'],
		leverageMin: 1,
		leverageMax: 50,
		baseToken: 'USDC',
		tradeTokens: [
			'BTC',
			'ETH',
			'SOL',
			'XRP',
			'LTC',
			'UNI',
			'ADA',
			'TON',
			'DOGE',
			'SHIB',
			'AVAX',
			'TRX',
			'DOT',
			'BCH',
			'BNB',
			'LINK',
			'ETC',
			// 'POL',
			'NEAR',
			'PEPE',
			'1INCH',
			'APT',
			'MNT',
			'ONDO',
			'NOT',
			'FIL',
			'ARB',
			'XMR',
			'XLM',
			'MEW',
		],
		tradeEnabled: true,
	},
	ig: {
		displayName: 'IG',
		name: 'ig',
		marginModels: ['cross'],
		leverageMin: 5,
		leverageMax: 5,
		baseToken: 'EUR',
		tradeTokens: ['TSLA', 'NVDA', 'HEIA', 'HEIO', 'IBM', 'ASML'],
		tradeEnabled: false,
	},
	'ig-demo': {
		displayName: 'IG demo',
		name: 'ig',
		marginModels: ['cross'],
		leverageMin: 5,
		leverageMax: 5,
		baseToken: 'EUR',
		tradeTokens: ['TSLA', 'NVDA', 'HEIA', 'HEIO', 'IBM', 'ASML'],
		tradeEnabled: false,
	},
};

const exchangesKeys = Object.keys(EXCHANGES);
exchangesKeys.forEach(exchangeKey => {
	const exchange = EXCHANGES[exchangeKey];
	if (Array.isArray(exchange.tradeTokens)) {
		exchange.tradeTokens.sort();
	}
});

// choose a default exchange, or - if not present (when made a typo) - choose the first exchange-item
// export const DEFAULT_EXCHANGE = EXCHANGES['dydx-testnet'] || EXCHANGES[Object.keys(EXCHANGES)[0]];
export const DEFAULT_EXCHANGE = EXCHANGES.phemex || EXCHANGES[Object.keys(EXCHANGES)[0]];

// mapping specific Kraken symbols into regular symbols
export const SYMBOL_MAPPING = {
	ZUSD: 'USD',
	XXBT: 'BTC',
};

export const headerNavItems = [
	// { label: 'Analyse', url: '/analyse' },
	{ label: 'Dashboard', url: '/dashboard' },
	{ label: 'Strategies', url: '/strategies' },
	{ label: 'Simulators', url: '/simulators' },
	// { label: 'Balance', url: '/balance' },
	// { label: 'Trade History', url: '/trade-history' },
];

export const userNavItems = [
	{ label: 'My Account', url: '/profile/' },
	// { label: 'User management', url: '/user-management/', scope: 'admin' },
];

export const userNavProfile = [
	{ label: 'Profile', url: '/profile/' },
	{ label: 'Password', url: '/password/' },
	{ label: 'Settings', url: '/settings/' },
	{ label: 'Trading Configuration', url: '/trading-configuration/' },
];

export const USD_DECIMALS = 6;

// DyDx testnet Cosmos:
// https://v4.testnet.dydx.exchange/#/trade/ETH-USD
// announcement: https://dydx.exchange/blog/public-testnet
// https://twitter.com/dydx

export const TRADE_POINTS = {
	[ARBITRUM]: {
		BASIS_POINTS_DIVISOR_BN: 10000n,
		TAX_BASIS_POINTS_BN: 60n,
		STABLE_TAX_BASIS_POINTS_BN: 5n,
		MINT_BURN_FEE_BASIS_POINTS_BN: 25n,
		SWAP_FEE_BASIS_POINTS_BN: 25n,
		STABLE_SWAP_FEE_BASIS_POINTS_BN: 1n,
		MARGIN_FEE_BASIS_POINTS_BN: 10n,
		LIQUIDATION_FEE_BN: expandBnDecimals(5, USD_DECIMALS),
		TRADES_PAGE_SIZE_BN: 100,
		PRECISION: expandBnDecimals(1, 30),
	},
	[PHEMEX]: {
		BASIS_POINTS_DIVISOR_BN: 10000n,
		TAX_BASIS_POINTS_BN: 60n,
		STABLE_TAX_BASIS_POINTS_BN: 5n,
		MINT_BURN_FEE_BASIS_POINTS_BN: 25n,
		SWAP_FEE_BASIS_POINTS_BN: 25n,
		STABLE_SWAP_FEE_BASIS_POINTS_BN: 1n,
		MARGIN_FEE_BASIS_POINTS_BN: 10n,
		LIQUIDATION_FEE_BN: expandBnDecimals(5, USD_DECIMALS),
		TRADES_PAGE_SIZE_BN: 100,
		PRECISION: expandBnDecimals(1, 30),
	},
};

export const FEES = {
	[ARBITRUM]: {
		SWAP_ORDER_EXECUTION_GAS_FEE: expandBnDecimals(0.0003, 18),
		INCREASE_ORDER_EXECUTION_GAS_FEE: expandBnDecimals(0.0003, 18),
		DECREASE_ORDER_EXECUTION_GAS_FEE: expandBnDecimals(0.000300001, 18),
	},
	[ARBITRUM_TESTNET]: {
		SWAP_ORDER_EXECUTION_GAS_FEE: expandBnDecimals(0.0003, 18),
		INCREASE_ORDER_EXECUTION_GAS_FEE: expandBnDecimals(0.0003, 18),
		DECREASE_ORDER_EXECUTION_GAS_FEE: expandBnDecimals(0.000300001, 18),
	},
	[AVALANCHE]: {
		SWAP_ORDER_EXECUTION_GAS_FEE: expandBnDecimals(0.01, 18),
		INCREASE_ORDER_EXECUTION_GAS_FEE: expandBnDecimals(0.01, 18),
		DECREASE_ORDER_EXECUTION_GAS_FEE: expandBnDecimals(0.0100001, 18),
	},
	[AVALANCHE_FUJI]: {
		SWAP_ORDER_EXECUTION_GAS_FEE: expandBnDecimals(0.01, 18),
		INCREASE_ORDER_EXECUTION_GAS_FEE: expandBnDecimals(0.01, 18),
		DECREASE_ORDER_EXECUTION_GAS_FEE: expandBnDecimals(0.0100001, 18),
	},
};

export const BALANCE_PERCENT_LIST = [
	'2',
	'4',
	'5',
	'10',
	'15',
	'20',
	'25',
	'30',
	'35',
	'40',
	'45',
	'50',
	'60',
	'70',
	'80',
	'90',
	'100',
];

export const TRADE_DIRECTION_LIST = ['long', 'short', 'both'];
