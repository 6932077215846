import React from 'react';
import PropTypes from 'prop-types';
import ConfirmDialog from 'components/common/dialogs/ConfirmDialog';

const ConfirmModal = props => {
	const { classNameCloseButton, classNameHeader, onClose, title } = props;

	let header;
	if (title || onClose) {
		let closeicon;
		if (onClose) {
			closeicon = (
				<button className={classNameCloseButton} onClick={onClose}>
					x
				</button>
			);
		}

		header = (
			<div className={classNameHeader}>
				{title}
				{closeicon}
			</div>
		);
	}
	return (
		<div>
			{header}
			<ConfirmDialog onClose={null} {...props} />
		</div>
	);
};

ConfirmModal.defaultProps = {
	className:
		'fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 text-2xl flex items-center px-8 py-12 bg-blue-500',
	classNameHeader: '',
};

ConfirmModal.propTypes = {
	className: PropTypes.string,
	classNameCloseButton: PropTypes.string,
	classNameHeader: PropTypes.string,
	classNameIcon: PropTypes.string,
	classNameYesNoContainer: PropTypes.string,
	classNameYesButton: PropTypes.string,
	classNameNoButton: PropTypes.string,
	handleClose: PropTypes.func,
	message: PropTypes.string.isRequired,
	onClose: PropTypes.func,
	onNo: PropTypes.func.isRequired,
	onYes: PropTypes.func.isRequired,
	severity: PropTypes.oneOf(['error', 'info', 'success', 'warning', 'question']),
	title: PropTypes.string,
};

export default ConfirmModal;
