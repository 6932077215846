import React, { Fragment, useState, useRef, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import authenticationCtx from 'context/authentication';
import balancesCtx from 'context/balances';
import tradesCtx from 'context/trades';
import darkmodeCtx from 'context/darkmode';
import exchangeCtx from 'context/exchange';
import accountCtx from 'context/account';
import floatToText from 'lib/float-to-text';
import { Link, navigate } from 'gatsby';
import { Listbox, Transition } from '@headlessui/react';
import useClickOutside from 'hooks/useClickOutside';
import { headerNavItems, userNavItems, ENVIRONMENT, EXCHANGES } from 'config/constants';
import useHasMounted from 'hooks/useHasMounted';
import Logo from 'assets/images/svg/logo.inline.svg';
import { later } from 'lib/timers';
import CheckIcon from 'assets/images/svg/check.svg';

const EXCHANGES_ARRAY = [];
const EXCHANGES_LABELS = {};

Object.keys(EXCHANGES).forEach(key => {
	const exchangeObject = EXCHANGES[key];
	if (exchangeObject.tradeEnabled) {
		EXCHANGES_ARRAY.push(key);
		EXCHANGES_LABELS[key] = exchangeObject.displayName;
	}
});

const Header = props => {
	const { accountdata } = useContext(accountCtx);
	const { balances } = useContext(balancesCtx);
	const { exchange, setExchange } = useContext(exchangeCtx);
	const { positions, subscribePositions, unsubscribePositions } = useContext(tradesCtx);
	const { darkmode, toggleDarkmode } = useContext(darkmodeCtx);
	const hasMounted = useHasMounted();
	const [selectedExchange, setSelectedExchange] = useState(EXCHANGES_ARRAY[0]);
	const [balanceVisible, setBalanceVisible] = useState(false);
	const [open, setOpen] = useState(false);
	const [openUserManagement, setOpenUserManagement] = useState(false);
	const [showUserToolTip, setShowUserToolTip] = useState(false);
	const { isLoggedIn, logout, scope } = useContext(authenticationCtx);
	const userMenuRef = useRef(null);
	const timerRef = useRef(null);
	const positionsCount = positions.length;

	let developmentLabel;

	useEffect(() => {
		subscribePositions();

		return () => {
			if (timerRef.current) {
				timerRef.current.cancel();
			}
			unsubscribePositions();
		};
	}, []);

	useEffect(() => {
		setExchange(selectedExchange);
	}, [selectedExchange]);

	useEffect(() => {
		timerRef.current = later(() => {
			setBalanceVisible(true);
		}, 100);
	}, [exchange]);

	const changeExchange = newExchange => {
		// make sure first to make the balances invisible
		// because the "exchange"-button will change from witdh when the selection is chaned, this would lead into a shift from the balance text, which we want to avoid
		setBalanceVisible(false);
		setSelectedExchange(newExchange);
	};

	const navigateTo = url => {
		setOpen(false);
		setOpenUserManagement(false);
		navigate(url);
	};

	const handleLogout = () => {
		setOpenUserManagement(false);
		navigate('/');
		logout();
	};

	const handleOpenNavMenu = (e, isOpen) => {
		if (e && e.type === 'keydown' && (e.key === 'Tab' || e.key === 'Shift')) {
			return;
		}
		setOpen(!!isOpen);
	};

	const handleOpenUserMenu = (e, isOpen) => {
		if (e && e.type === 'keydown' && (e.key === 'Tab' || e.key === 'Shift')) {
			return;
		}
		setShowUserToolTip(false);
		setOpenUserManagement(!!isOpen);
	};

	useClickOutside([userMenuRef], handleOpenUserMenu);

	if (!hasMounted) {
		return null;
	}

	if (ENVIRONMENT === 'development') {
		developmentLabel = <div className="ml-auto">Development</div>;
	}

	const logo = (
		<Link to="/" className="my-auto">
			<Logo className="h-10" />
		</Link>
	);

	let menuItems;
	let menuItemsUser;
	let avatarOrLogin = (
		<button
			type="button"
			className="bg-white rounded-full px-6 py-1 text-black"
			onClick={() => navigateTo('/dashboard')}
		>
			Login
		</button>
	);

	if (isLoggedIn) {
		menuItems = headerNavItems.map(({ label, url }) => {
			const isDashboardPage = label.toLowerCase() === 'dashboard';
			const showPositions = isDashboardPage && positionsCount > 0;
			const path = window.location.pathname;
			const dashboardPageSelected = path === '/dashboard/';

			let positionsCountNode;
			if (showPositions) {
				positionsCountNode = (
					<span
						className={clsx('py-1 px-2 rounded-full text-center ml-2', {
							'bg-yellow-300 text-black': dashboardPageSelected,
							'bg-blue-500 text-white': !dashboardPageSelected,
						})}
					>
						{positionsCount}
					</span>
				);
			}

			return (
				<Link
					to={url}
					className={clsx(
						'form-input hover:bg-blue-300 hover:text-black w-full font-normal rounded border border-indigo-300 bg-transparent px-4 py-3 text-white placeholder:text-white/25',
						{
							'pr-6': !showPositions,
							'pr-4': showPositions,
						},
					)}
					activeClassName="!bg-blue-500 !text-white !cursor-default"
					key={url}
				>
					{label}
					{positionsCountNode}
				</Link>
			);
		});

		menuItemsUser = userNavItems.map(({ label, url, scope }) => (
			<Link to={url} className="p-4 hover:bg-blue-500 hover:text-white" key={url}>
				{label}
			</Link>
		));

		const fullUserName = '';
		menuItemsUser.push(
			<button type="button" className="p-4 hover:bg-blue-500 hover:text-white" onClick={handleLogout} key="logout">
				Logout
			</button>,
		);
		avatarOrLogin = (
			<div className="relative" ref={userMenuRef}>
				<button
					type="button"
					className="border border-white p-1 rounded"
					onClick={e => handleOpenUserMenu(e, !openUserManagement)}
				>
					<img alt={fullUserName} src="/images/avatar.png" width="40" height="40" />
				</button>

				<div
					onClick={e => handleOpenUserMenu(e, false)}
					className={clsx(
						'flex flex-col mt-0 shadow-xl rounded absolute z-20 bg-white text-black right-0 whitespace-nowrap',
						{
							'invisible opacity-0': !openUserManagement,
							'visible opacity-100': openUserManagement,
						},
					)}
				>
					{menuItemsUser}
				</div>
			</div>
		);
	}

	menuItems = <nav className="flex space-x-4">{menuItems}</nav>;

	const hamburgerMenu = (
		<>
			<button onClick={event => handleOpenNavMenu(event, true)}>Menu</button>
			{menuItems}
		</>
	);

	const handleCloseUserToolTip = () => {
		setShowUserToolTip(false);
	};

	const handleOpenUserToolTip = () => {
		if (!openUserManagement) {
			setShowUserToolTip(true);
		}
	};

	let balanceNode;
	if (isLoggedIn && balanceVisible && balances[0]) {
		balanceNode = floatToText(balances[0].balance, { append: 'balance: ', append: ' USD' });
	}

	const usedExchanges = EXCHANGES_ARRAY.filter(exchangeKey => accountdata?.apikeys[exchangeKey]);

	const selectExchangeList = (
		<div>
			<Listbox value={selectedExchange} onChange={changeExchange} className="flex flex-col gap-1 font-semibold">
				<div className="relative">
					<Listbox.Button
						className={clsx(
							'form-input w-full font-normal rounded border border-indigo-300 bg-transparent px-4 py-3 text-white placeholder:text-white/25',
							{
								'cursor-default': usedExchanges.length < 2,
								'hover:bg-blue-300 hover:text-black': usedExchanges.length > 1,
							},
						)}
						onClick={e => {
							if (usedExchanges.length < 2) {
								e.preventDefault(); // Prevent interaction when disabled
							}
						}}
					>
						<span className="block truncate text-left uppercase">{selectedExchange}</span>
					</Listbox.Button>

					<Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
						<Listbox.Options className="absolute right-0 mt-12 w-300 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
							{usedExchanges.map(exchange => (
								<Listbox.Option
									className={({ active }) =>
										`relative cursor-default select-none py-2 pl-10 pr-4 cursor-pointer ${
											active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
										}`
									}
									value={exchange}
									key={exchange}
								>
									{({ selectedExchange }) => (
										<>
											<span className={`block truncate uppercase ${selectedExchange ? 'font-medium' : 'font-normal'}`}>
												{EXCHANGES_LABELS[exchange]}
											</span>
											{selectedExchange ? (
												<span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
													<CheckIcon className="text-yellow-400 w-6 h-6" />
												</span>
											) : null}
										</>
									)}
								</Listbox.Option>
							))}
						</Listbox.Options>
					</Transition>
				</div>
			</Listbox>
		</div>
	);

	return (
		<header className="container-fluid py-2 bg-black-42 border-b border-white">
			<div className="container flex items-center place-content-between">
				<div className="container flex justify-start items-center gap-6">
					{logo}
					{menuItems}
				</div>
				<div className="text-3xl text-yellow-300 font-bold">{developmentLabel}</div>
				<div className="container flex justify-end items-center gap-6">
					<div className="text-xl">
						<span className="text-blue-400 font-bold">{balanceNode}</span>
					</div>
					{selectExchangeList}
					{avatarOrLogin}
				</div>
			</div>
		</header>
	);
};

Header.defaultProps = {};

Header.propTypes = {
	uri: PropTypes.string.isRequired,
};

export default Header;
