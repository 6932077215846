import React, { useContext, useEffect, useRef } from 'react';
import createStore from 'ctx-provider';
import managedPromise from 'lib/managedPromise';
import ConfirmModal from 'components/common/modals/ConfirmModal';
import feedbackCtx from 'context/feedback';

const useConfirm = () => {
	const { add, remove } = useContext(feedbackCtx);

	/**
	 * Shows a confirmation modal with yes and no buttons.
	 * @param {string} message Modal content
	 * @param {object {message string, title string, severity ('success'|'error'|'warning'|'info')}}
	 * @returns {Promise<bool>} Boolean indicating whether yes or no was clicked
	 */
	const confirm = (message, options = {}) => {
		const promise = managedPromise();

		const fulfill = value => {
			remove(promise.id);
			promise.fulfill(value);
		};

		const onYes = () => {
			fulfill(true);
		};

		const onNo = () => {
			fulfill(false);
		};

		const content = (
			<ConfirmModal title={options.title} message={message} onYes={onYes} onNo={onNo} severity={options.severity} />
		);

		promise.id = add({ content, backdrop: true }, options.severity);

		return promise;
	};

	return confirm;
};

const store = createStore(useConfirm);

export const { Provider } = store;
export default store.ctx;
