import React, { useContext, useEffect, useRef } from 'react';
import createStore from 'ctx-provider';
import managedPromise from 'lib/managedPromise';
import AlertModal from 'components/common/modals/AlertModal';
import feedbackCtx from 'context/feedback';

const useAlert = () => {
	const { add, remove } = useContext(feedbackCtx);

	/**
	 * Shows a alert modal with an ok button.
	 * @param {string} message Modal content
	 * @param {object {message string, title string, severity ('success'|'error'|'warning'|'info')}}
	 * @returns {Promise<bool>} Boolean indicating whether yes or no was clicked
	 */
	const alert = (message, options = {}) => {
		const promise = managedPromise();

		const fulfill = value => {
			remove(promise.id);
			promise.fulfill(value);
		};

		const onOk = () => {
			fulfill(true);
		};

		const content = <AlertModal title={options.title} message={message} onOk={onOk} severity={options.severity} />;

		promise.id = add({ content, backdrop: true }, options.severity);

		return promise;
	};

	return alert;
};

const store = createStore(useAlert);

export const { Provider } = store;
export default store.ctx;
