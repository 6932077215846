import { useState } from 'react';
import createStore from 'ctx-provider';
import { cloneDeep, isEqual } from 'lodash';
import { DEFAULT_EXCHANGE, EXCHANGES } from 'config/constants';

const useExchange = () => {
	const [exchange, setExchangeObject] = useState(DEFAULT_EXCHANGE && cloneDeep(DEFAULT_EXCHANGE));

	const setExchange = key => {
		const newExchangeObject = EXCHANGES[key];
		if (!isEqual(newExchangeObject, exchange)) {
			setExchangeObject(cloneDeep(newExchangeObject));
		}
	};

	return {
		exchange,
		setExchange,
	};
};

const store = createStore(useExchange);

export const { Provider } = store;
export default store.ctx;
