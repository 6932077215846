import { useEffect, useState, useRef, useContext } from 'react';
import createStore from 'ctx-provider';
import {
	accountGetSessionUser,
	accountActivation,
	accountChangeExchangeKey,
	accountChangeExchangeEnterPosition,
	accountChangeExchangeTradeSettings,
	accountChangeUsername,
	accountChangePassword,
	accountChangeRole,
	accountList,
	accountNew,
	accountRemoval,
} from 'api/trading-api';
import { REFRESH_TIME_ACCOUNTDATA_SEC } from 'config/constants';
import toast from 'react-hot-toast';

// REFRESH_TIME_ACCOUNTDATA_MS is used to ensure that accountdata also is up to date when changed from another computer
const REFRESH_TIME_ACCOUNTDATA_MS = REFRESH_TIME_ACCOUNTDATA_SEC * 1000;

const useAccounts = () => {
	const [accountdata, setAccountdata] = useState();
	const polling = useRef();
	const timeoutId = useRef();

	const startPolling = () => {
		polling.current = true;
		loadData();
	};

	const stopPolling = () => {
		cancelTimeout();
	};

	const cancelTimeout = () => {
		if (timeoutId.current) {
			clearTimeout(timeoutId.current);
		}
	};

	useEffect(() => {
		return () => {
			cancelTimeout();
		};
	}, []);

	const loadData = async () => {
		cancelTimeout(); // in case loadData wasn't triggered by the timer
		try {
			if (polling.current) {
				const data = await accountGetSessionUser();
				setAccountdata(data);
			}
		} catch (err) {
			const message = err.response?.data?.error || err.message || 'Server error';
			// send an alert, but prevent alerting multiple times, by marking this message with "match":
			// alert(message, { noDupes: true, match: `${message}#error`, severity: 'error' });
		}
		if (polling.current) {
			timeoutId.current = setTimeout(loadData, REFRESH_TIME_ACCOUNTDATA_MS);
		}
	};

	/*
        Function saveExchangeKey
        NOTE: exchangeKeys should be an object like this:

        In case exchange === 'gmx'
        {
            arbitrum: '0xa35f...'
        }

        In case exchange === 'phemex'
        {
            apikeyprivate: '0xa35f...'
            apikeypublic: '0xa35f...'
        }
    */
	const saveExchangeKey = async (exchange, exchangeKeys) => {
		try {
			accountChangeExchangeKey({ exchange, exchangeKeys });
			toast.promise(loadData(), {
				loading: 'Updating...',
				success: 'Successfully updated!',
				error: 'Error when fetching',
			});
		} catch (err) {
			const message = err.response?.data?.error || err.message || 'Server error';
			// send an alert, but prevent alerting multiple times, by marking this message with "match":
			toast.error(message);
			// alert(message, { noDupes: true, match: `${message}#error`, severity: 'error' });
		}
	};

	const saveExchangeEnterPositions = async (exchange, exchangeEnterPosition) => {
		try {
			accountChangeExchangeEnterPosition({ exchange, exchangeEnterPosition });
			toast.promise(loadData(), {
				loading: 'Updating...',
				success: 'Successfully updated!',
				error: 'Error when fetching',
			});
		} catch (err) {
			const message = err.response?.data?.error || err.message || 'Server error';
			// send an alert, but prevent alerting multiple times, by marking this message with "match":
			toast.error(message);
			// alert(message, { noDupes: true, match: `${message}#error`, severity: 'error' });
		}
	};

	const saveExchangeTradeSettings = async (
		exchange,
		{
			margintradereserves,
			ordertypetrades,
			ordertypestoploss,
			overbidlimittrades,
			overbidlimitstoploss,
			strategytradesresultcorrection,
		},
	) => {
		try {
			accountChangeExchangeTradeSettings({
				exchange,
				margintradereserves,
				ordertypetrades,
				ordertypestoploss,
				overbidlimittrades,
				overbidlimitstoploss,
				strategytradesresultcorrection,
			});
			toast.promise(loadData(), {
				loading: 'Updating...',
				success: 'Successfully updated!',
				error: 'Error when fetching',
			});
		} catch (err) {
			const message = err.response?.data?.error || err.message || 'Server error';
			// send an alert, but prevent alerting multiple times, by marking this message with "match":
			toast.error(message);
			// alert(message, { noDupes: true, match: `${message}#error`, severity: 'error' });
		}
	};

	const setPassword = async (oldPassword, newPassword) => {
		try {
			accountChangePassword({ oldPassword, newPassword });
			toast.success('Successfully updated!');
		} catch (err) {
			const message = err.response?.data?.error || err.message || 'Server error';
			toast.error(message);
			// send an alert, but prevent alerting multiple times, by marking this message with "match":
			// alert(message, { noDupes: true, match: `${message}#error`, severity: 'error' });
		}
	};

	const setEmail = async accountname => {
		try {
			accountChangeUsername({ accountname });
			toast.promise(loadData(), {
				loading: 'Updating...',
				success: 'Successfully updated!',
				error: 'Error when fetching',
			});
		} catch (err) {
			const message = err.response?.data?.error || err.message || 'Server error';
			toast.error(message);
			// send an alert, but prevent alerting multiple times, by marking this message with "match":
			// alert(message, { noDupes: true, match: `${message}#error`, severity: 'error' });
		}
	};

	const activateAccount = async accounthash => {
		try {
			accountActivation({ accounthash, active: true });
		} catch (err) {
			const message = err.response?.data?.error || err.message || 'Server error';
			// send an alert, but prevent alerting multiple times, by marking this message with "match":
			alert(message, { noDupes: true, match: `${message}#error`, severity: 'error' });
		}
	};

	const deactivateAccount = async accounthash => {
		try {
			accountActivation({ accounthash, active: false });
		} catch (err) {
			const message = err.response?.data?.error || err.message || 'Server error';
			// send an alert, but prevent alerting multiple times, by marking this message with "match":
			alert(message, { noDupes: true, match: `${message}#error`, severity: 'error' });
		}
	};

	const setRole = async (accounthash, role) => {
		try {
			accountChangeRole({ accounthash, role });
		} catch (err) {
			const message = err.response?.data?.error || err.message || 'Server error';
			// send an alert, but prevent alerting multiple times, by marking this message with "match":
			alert(message, { noDupes: true, match: `${message}#error`, severity: 'error' });
		}
	};

	const listAccounts = async () => {
		let result;
		try {
			result = await accountList();
		} catch (err) {
			const message = err.response?.data?.error || err.message || 'Server error';
			// send an alert, but prevent alerting multiple times, by marking this message with "match":
			alert(message, { noDupes: true, match: `${message}#error`, severity: 'error' });
			result = [];
		}
		return result;
	};

	const createAccount = async (accountname, password, role) => {
		let result;
		try {
			result = await accountNew({ accountname, password, role });
		} catch (err) {
			const message = err.response?.data?.error || err.message || 'Server error';
			// send an alert, but prevent alerting multiple times, by marking this message with "match":
			alert(message, { noDupes: true, match: `${message}#error`, severity: 'error' });
		}
		return result;
	};

	const deleteAccount = async accounthash => {
		let result;
		try {
			await accountRemoval({ accounthash });
			result = true;
		} catch (err) {
			const message = err.response?.data?.error || err.message || 'Server error';
			// send an alert, but prevent alerting multiple times, by marking this message with "match":
			alert(message, { noDupes: true, match: `${message}#error`, severity: 'error' });
			result = false;
		}
		return result;
	};

	return {
		saveExchangeKey, // by user
		saveExchangeTradeSettings, // by user
		saveExchangeEnterPositions, // by user
		setPassword, // by user
		setEmail, // by user
		startPolling,
		stopPolling,
		activateAccount, // by an admin
		deactivateAccount, // by an admin
		setRole, // by an admin
		accountdata, // life data from the user that is logged in
		listAccounts, // by an admin
		createAccount, // by an admin
		deleteAccount, // by an admin
	};
};

const store = createStore(useAccounts);

export const { Provider } = store;
export default store.ctx;
