import { useState, useRef, useEffect, useContext } from 'react';
import accountCtx from 'context/account';
import createStore from 'ctx-provider';
import { getScope, ping, logout as apiLogout } from 'api/trading-api';
import { later } from 'lib/timers';
import useAlert from 'hooks/useAlert';
import { LOGOUT_AFTER_IDDLE, LOGOUT_AFTER_IDDLE_MIN } from 'config/constants';

const KEEP_ALIVE_MINS = 1;
const PING_TIME_MS = 1000 * 60 * KEEP_ALIVE_MINS;
const LOGOUT_AFTER_IDDLE_MS = 1000 * 60 * LOGOUT_AFTER_IDDLE_MIN;

const useAuthentication = () => {
	const { startPolling, stopPolling } = useContext(accountCtx);
	const alert = useAlert();
	const [scope, setScope] = useState();
	const [initialized, setInitialized] = useState(false);
	const timer = useRef();
	const lastActivityRef = useRef(Date.now());
	const handleActivity = useRef();

	const startActivityListeners = () => {
		document.addEventListener('keydown', handleActivity.current);
		document.addEventListener('pointermove', handleActivity.current);
	};

	const stopActivityListeners = () => {
		document.removeEventListener('keydown', handleActivity.current);
		document.removeEventListener('pointermove', handleActivity.current);
	};

	const startHealthCheck = () => {
		if (LOGOUT_AFTER_IDDLE) {
			timer.current = later(
				async () => {
					const iddleMs = Date.now() - lastActivityRef.current;
					if (iddleMs >= LOGOUT_AFTER_IDDLE_MS) {
						// TODO: fix the error that is thrown after logout
						console.debug('going to logout', {
							lastActivityRef_current: lastActivityRef.current,
							date_now: Date.now(),
							iddleMs,
							LOGOUT_AFTER_IDDLE_MS,
						});
						console.debug('LOGOUT fase A');
						logout();
					} else {
						const latestScope = await ping();
						if (!latestScope) {
							console.debug('NO latestScope');
							// logout();
						} else if (latestScope !== scope) {
							setScope(latestScope);
						}
					}
				},
				PING_TIME_MS,
				true,
			);
			startActivityListeners();
		}
	};

	const stopHealthCheck = () => {
		if (LOGOUT_AFTER_IDDLE) {
			if (timer.current) {
				timer.current.cancel();
			}
			stopActivityListeners();
		}
	};

	const logout = () => {
		setScope(null);
		stopHealthCheck();
		apiLogout();
	};

	const tryLogin = async (account, password) => {
		let success;
		try {
			const latestScope = await getScope({ account, password });
			if (latestScope) {
				success = true;
				setScope(latestScope);
				startHealthCheck();
				startPolling();
			}
		} catch (err) {
			console.debug(err);
			if (err?.response?.status === 401) {
				alert(err?.response?.data?.error || 'Wrong credentials');
			} else {
				console.error(err);
			}
		}
		return !!success;
	};

	const initializeScope = async () => {
		const latestScope = await ping();
		if (latestScope) {
			setScope(latestScope);
			startHealthCheck();
			startPolling();
		}
		setInitialized(true);
	};

	useEffect(() => {
		handleActivity.current = () => {
			lastActivityRef.current = Date.now();
		};
		initializeScope();

		return () => {
			stopHealthCheck();
			stopPolling();
		};
	}, []);

	return {
		initialized,
		isLoggedIn: !!scope,
		scope,
		logout,
		tryLogin,
	};
};

const store = createStore(useAuthentication);

export const { Provider } = store;
export default store.ctx;
