import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';

const GlobalSimulationParams = ({ configBoundaries, onClose, onStartSimulation, stoploss = 1 }) => {
	const indicatorPrefix = '-simulation-global-';
	const [boundaries, setBoundaries] = useState(cloneDeep(configBoundaries));
	const [fixedStoploss, setFixedStoploss] = useState(cloneDeep(stoploss));
	const [forSimulation, setForSimulation] = useState({});

	const handleSimulate = () => {
		const data = {};
		const keys = Object.keys(forSimulation);
		keys.forEach(key => {
			if (forSimulation[key]) {
				if (key === 'useProfitTrailingSecurity') {
					key = 'useProfitTrailingSecurityActivatedAtPriceMove';
					data[key] = cloneDeep(boundaries[key]);
					key = 'useProfitTrailingSecurityTakeTrailingProfit';
					data[key] = cloneDeep(boundaries[key]);
				} else if (key === 'closeOnImmediateConsecutiveNrCandlesReversal') {
					data[key] = cloneDeep(boundaries[key]);
					key = 'closeOnImmediateConsecutiveNrCandlesReversalArea';
					data[key] = cloneDeep(boundaries[key]);
				} else if (key === 'preventEnterWhenPricechangeIsBeyond') {
					data[key] = cloneDeep(boundaries[key]);
					key = 'preventEnterOnExtremePriceArea';
					data[key] = cloneDeep(boundaries[key]);
				} else {
					data[key] = cloneDeep(boundaries[key]);
				}
			}
		});
		onStartSimulation(data, fixedStoploss);
	};

	const switchCheckbox = key => {
		const newForSimulation = cloneDeep(forSimulation);
		newForSimulation[key] = !newForSimulation[key];
		setForSimulation(newForSimulation);
	};

	const handleChangePreventEnterWhenPricechangeIsBeyondMin = e => {
		let value = e.target.value;
		if (value !== '') {
			value = parseFloat(value);
			if (value < 0.01) {
				value = 0.01;
			} else if (value > 9.99) {
				value = 9.99;
			}
		}
		const newBoundaries = cloneDeep(boundaries);
		newBoundaries.preventEnterWhenPricechangeIsBeyond.min = value;
		setBoundaries(newBoundaries);
	};

	const handleChangePreventEnterWhenPricechangeIsBeyondMax = e => {
		let value = e.target.value;
		if (value !== '') {
			value = parseFloat(value);
			if (value < 0.02) {
				value = 0.02;
			} else if (value > 10) {
				value = 10;
			}
		}
		const newBoundaries = cloneDeep(boundaries);
		newBoundaries.preventEnterWhenPricechangeIsBeyond.max = value;
		setBoundaries(newBoundaries);
	};

	const handleChangePreventEnterWhenPricechangeIsBeyondStep = e => {
		let value = e.target.value;
		if (value !== '') {
			value = parseFloat(value);
			if (value < 0.01) {
				value = 0.01;
			} else if (value > 0.5) {
				value = 0.5;
			}
		}
		const newBoundaries = cloneDeep(boundaries);
		newBoundaries.preventEnterWhenPricechangeIsBeyond.step = value;
		setBoundaries(newBoundaries);
	};

	const handleChangePreventEnterOnExtremePriceAreaMin = e => {
		let value = e.target.value;
		if (value !== '') {
			value = parseInt(value, 10);
			if (value < 1) {
				value = 1;
			} else if (value > 5) {
				value = 5;
			}
		}
		const newBoundaries = cloneDeep(boundaries);
		newBoundaries.preventEnterOnExtremePriceArea.min = value;
		setBoundaries(newBoundaries);
	};

	const handleChangePreventEnterOnExtremePriceAreaMax = e => {
		let value = e.target.value;
		if (value !== '') {
			value = parseInt(value, 10);
			if (value < 2) {
				value = 2;
			} else if (value > 10) {
				value = 10;
			}
		}
		const newBoundaries = cloneDeep(boundaries);
		newBoundaries.preventEnterOnExtremePriceArea.max = value;
		setBoundaries(newBoundaries);
	};

	const handleChangePreventEnterOnExtremePriceAreaStep = e => {
		let value = e.target.value;
		if (value !== '') {
			value = parseInt(value, 10);
			if (value < 1) {
				value = 1;
			} else if (value > 5) {
				value = 5;
			}
		}
		const newBoundaries = cloneDeep(boundaries);
		newBoundaries.preventEnterOnExtremePriceArea.step = value;
		setBoundaries(newBoundaries);
	};

	const handleChangePreventEnterWithinCandlesAfterClosePreviousPositionMin = e => {
		let value = e.target.value;
		if (value !== '') {
			value = parseInt(value, 10);
			if (value < 1) {
				value = 1;
			} else if (value > 99) {
				value = 99;
			}
		}
		const newBoundaries = cloneDeep(boundaries);
		newBoundaries.preventEnterWithinCandlesAfterClosePreviousPosition.min = value;
		setBoundaries(newBoundaries);
	};

	const handleChangePreventEnterWithinCandlesAfterClosePreviousPositionMax = e => {
		let value = e.target.value;
		if (value !== '') {
			value = parseInt(value, 10);
			if (value < 2) {
				value = 2;
			} else if (value > 100) {
				value = 100;
			}
		}
		const newBoundaries = cloneDeep(boundaries);
		newBoundaries.preventEnterWithinCandlesAfterClosePreviousPosition.max = value;
		setBoundaries(newBoundaries);
	};

	const handleChangePreventEnterWithinCandlesAfterClosePreviousPositionStep = e => {
		let value = e.target.value;
		if (value !== '') {
			value = parseInt(value, 10);
			if (value < 1) {
				value = 1;
			} else if (value > 10) {
				value = 10;
			}
		}
		const newBoundaries = cloneDeep(boundaries);
		newBoundaries.preventEnterWithinCandlesAfterClosePreviousPosition.step = value;
		setBoundaries(newBoundaries);
	};

	const handleChangeStoplossMin = e => {
		let value = e.target.value;
		if (value !== '') {
			value = parseFloat(value);
			if (value < 0.01) {
				value = 0.01;
			} else if (value > 9.99) {
				value = 9.99;
			}
		}
		const newBoundaries = cloneDeep(boundaries);
		newBoundaries.stoploss.min = value;
		setBoundaries(newBoundaries);
	};

	const handleChangeStoplossMax = e => {
		let value = e.target.value;
		if (value !== '') {
			value = parseFloat(value);
			if (value < 0.02) {
				value = 0.02;
			} else if (value > 10) {
				value = 10;
			}
		}
		const newBoundaries = cloneDeep(boundaries);
		newBoundaries.stoploss.max = value;
		setBoundaries(newBoundaries);
	};

	const handleChangeStoplossStep = e => {
		let value = e.target.value;
		if (value !== '') {
			value = parseFloat(value);
			if (value < 0.01) {
				value = 0.01;
			} else if (value > 0.5) {
				value = 0.5;
			}
		}
		const newBoundaries = cloneDeep(boundaries);
		newBoundaries.stoploss.step = value;
		setBoundaries(newBoundaries);
	};

	const handleChangeCloseOnImmediateConsecutiveNrCandlesReversalMin = e => {
		let value = e.target.value;
		if (value !== '') {
			value = parseInt(value, 10);
			if (value < 1) {
				value = 1;
			} else if (value > 99) {
				value = 99;
			}
		}
		const newBoundaries = cloneDeep(boundaries);
		newBoundaries.closeOnImmediateConsecutiveNrCandlesReversal.min = value;
		setBoundaries(newBoundaries);
	};

	const handleChangeCloseOnImmediateConsecutiveNrCandlesReversalMax = e => {
		let value = e.target.value;
		if (value !== '') {
			value = parseInt(value, 10);
			if (value < 2) {
				value = 2;
			} else if (value > 100) {
				value = 100;
			}
		}
		const newBoundaries = cloneDeep(boundaries);
		newBoundaries.closeOnImmediateConsecutiveNrCandlesReversal.max = value;
		setBoundaries(newBoundaries);
	};

	const handleChangeCloseOnImmediateConsecutiveNrCandlesReversalStep = e => {
		let value = e.target.value;
		if (value !== '') {
			value = parseInt(value, 10);
			if (value < 1) {
				value = 1;
			} else if (value > 25) {
				value = 25;
			}
		}
		const newBoundaries = cloneDeep(boundaries);
		newBoundaries.closeOnImmediateConsecutiveNrCandlesReversal.step = value;
		setBoundaries(newBoundaries);
	};

	const handleChangeCloseOnImmediateConsecutiveNrCandlesReversalAreaMin = e => {
		let value = e.target.value;
		if (value !== '') {
			value = parseInt(value, 10);
			if (value < 1) {
				value = 1;
			} else if (value > 99) {
				value = 99;
			}
		}
		const newBoundaries = cloneDeep(boundaries);
		newBoundaries.closeOnImmediateConsecutiveNrCandlesReversalArea.min = value;
		setBoundaries(newBoundaries);
	};

	const handleChangeCloseOnImmediateConsecutiveNrCandlesReversalAreaMax = e => {
		let value = e.target.value;
		if (value !== '') {
			value = parseInt(value, 10);
			if (value < 2) {
				value = 2;
			} else if (value > 100) {
				value = 100;
			}
		}
		const newBoundaries = cloneDeep(boundaries);
		newBoundaries.closeOnImmediateConsecutiveNrCandlesReversalArea.max = value;
		setBoundaries(newBoundaries);
	};

	const handleChangeCloseOnImmediateConsecutiveNrCandlesReversalAreaStep = e => {
		let value = e.target.value;
		if (value !== '') {
			value = parseInt(value, 10);
			if (value < 1) {
				value = 1;
			} else if (value > 25) {
				value = 25;
			}
		}
		const newBoundaries = cloneDeep(boundaries);
		newBoundaries.closeOnImmediateConsecutiveNrCandlesReversalArea.step = value;
		setBoundaries(newBoundaries);
	};

	const handleChangeUseProfitTrailingSecurityActivatedAtPriceMoveMin = e => {
		let value = e.target.value;
		if (value !== '') {
			value = parseFloat(value);
			if (value < 0.1) {
				value = 0.1;
			} else if (value > 4.99) {
				value = 4.99;
			}
		}
		const newBoundaries = cloneDeep(boundaries);
		newBoundaries.useProfitTrailingSecurityActivatedAtPriceMove.min = value;
		setBoundaries(newBoundaries);
	};

	const handleChangeUseProfitTrailingSecurityActivatedAtPriceMoveMax = e => {
		let value = e.target.value;
		if (value !== '') {
			value = parseFloat(value);
			if (value < 0.2) {
				value = 0.2;
			} else if (value > 5) {
				value = 5;
			}
		}
		const newBoundaries = cloneDeep(boundaries);
		newBoundaries.useProfitTrailingSecurityActivatedAtPriceMove.max = value;
		setBoundaries(newBoundaries);
	};

	const handleChangeUseProfitTrailingSecurityActivatedAtPriceMoveStep = e => {
		let value = e.target.value;
		if (value !== '') {
			value = parseFloat(value);
			if (value < 0.1) {
				value = 0.1;
			} else if (value > 1) {
				value = 1;
			}
		}
		const newBoundaries = cloneDeep(boundaries);
		newBoundaries.useProfitTrailingSecurityActivatedAtPriceMove.step = value;
		setBoundaries(newBoundaries);
	};

	const handleChangeUseProfitTrailingSecurityTakeTrailingProfitMin = e => {
		let value = e.target.value;
		if (value !== '') {
			value = parseInt(value, 10);
			if (value < 1) {
				value = 1;
			} else if (value > 99) {
				value = 99;
			}
		}
		const newBoundaries = cloneDeep(boundaries);
		newBoundaries.useProfitTrailingSecurityTakeTrailingProfit.min = value;
		setBoundaries(newBoundaries);
	};

	const handleChangeUseProfitTrailingSecurityTakeTrailingProfitMax = e => {
		let value = e.target.value;
		if (value !== '') {
			value = parseInt(value, 10);
			if (value < 2) {
				value = 2;
			} else if (value > 100) {
				value = 100;
			}
		}
		const newBoundaries = cloneDeep(boundaries);
		newBoundaries.useProfitTrailingSecurityTakeTrailingProfit.max = value;
		setBoundaries(newBoundaries);
	};

	const handleChangeUseProfitTrailingSecurityTakeTrailingProfitStep = e => {
		let value = e.target.value;
		if (value !== '') {
			value = parseInt(value, 10);
			if (value < 1) {
				value = 1;
			} else if (value > 10) {
				value = 10;
			}
		}
		const newBoundaries = cloneDeep(boundaries);
		newBoundaries.useProfitTrailingSecurityTakeTrailingProfit.step = value;
		setBoundaries(newBoundaries);
	};

	const handleChangePreventCloseWithinFirstNrCandlesMin = e => {
		let value = e.target.value;
		if (value !== '') {
			value = parseInt(value, 10);
			if (value < 1) {
				value = 1;
			} else if (value > 19) {
				value = 19;
			}
		}
		const newBoundaries = cloneDeep(boundaries);
		newBoundaries.preventCloseWithinFirstNrCandles.min = value;
		setBoundaries(newBoundaries);
	};

	const handleChangePreventCloseWithinFirstNrCandlesMax = e => {
		let value = e.target.value;
		if (value !== '') {
			value = parseInt(value, 10);
			if (value < 2) {
				value = 2;
			} else if (value > 20) {
				value = 20;
			}
		}
		const newBoundaries = cloneDeep(boundaries);
		newBoundaries.preventCloseWithinFirstNrCandles.max = value;
		setBoundaries(newBoundaries);
	};

	const handleChangePreventCloseWithinFirstNrCandlesStep = e => {
		let value = e.target.value;
		if (value !== '') {
			value = parseInt(value, 10);
			if (value < 1) {
				value = 1;
			} else if (value > 5) {
				value = 5;
			}
		}
		const newBoundaries = cloneDeep(boundaries);
		newBoundaries.preventCloseWithinFirstNrCandles.step = value;
		setBoundaries(newBoundaries);
	};

	const handleChangeMinProfitMin = e => {
		let value = e.target.value;
		if (value !== '') {
			value = parseFloat(value);
			if (value < 0) {
				value = 0;
			} else if (value > 100) {
				value = 100;
			}
		}
		const newBoundaries = cloneDeep(boundaries);
		newBoundaries.minProfit.min = value;
		setBoundaries(newBoundaries);
	};

	const handleChangeMinProfitMax = e => {
		let value = e.target.value;
		if (value !== '') {
			value = parseFloat(value);
			if (value < 1) {
				value = 1;
			} else if (value > 100) {
				value = 100;
			}
		}
		const newBoundaries = cloneDeep(boundaries);
		newBoundaries.minProfit.max = value;
		setBoundaries(newBoundaries);
	};

	const handleChangeMinProfitStep = e => {
		let value = e.target.value;
		if (value !== '') {
			value = parseInt(value, 10);
			if (value < 0.1) {
				value = 0.1;
			} else if (value > 50) {
				value = 50;
			}
		}
		const newBoundaries = cloneDeep(boundaries);
		newBoundaries.minProfit.step = value;
		setBoundaries(newBoundaries);
	};

	const handleChangePreventCloseWithinFirstNrCandlesOnlyDuringNrCandlesMin = e => {
		let value = e.target.value;
		if (value !== '') {
			value = parseInt(value, 10);
			if (value < 1) {
				value = 1;
			} else if (value > 499) {
				value = 499;
			}
		}
		const newBoundaries = cloneDeep(boundaries);
		newBoundaries.preventCloseWithinFirstNrCandlesOnlyDuringNrCandles.min = value;
		setBoundaries(newBoundaries);
	};

	const handleChangePreventCloseWithinFirstNrCandlesOnlyDuringNrCandlesMax = e => {
		let value = e.target.value;
		if (value !== '') {
			value = parseInt(value, 10);
			if (value < 2) {
				value = 2;
			} else if (value > 500) {
				value = 500;
			}
		}
		const newBoundaries = cloneDeep(boundaries);
		newBoundaries.preventCloseWithinFirstNrCandlesOnlyDuringNrCandles.max = value;
		setBoundaries(newBoundaries);
	};

	const handlePreventCloseWithinFirstNrCandlesOnlyDuringNrCandlesStep = e => {
		let value = e.target.value;
		if (value !== '') {
			value = parseInt(value, 10);
			if (value < 1) {
				value = 1;
			} else if (value > 50) {
				value = 50;
			}
		}
		const newBoundaries = cloneDeep(boundaries);
		newBoundaries.preventCloseWithinFirstNrCandlesOnlyDuringNrCandles.step = value;
		setBoundaries(newBoundaries);
	};

	const handleChangeFixedStoploss = e => {
		let value = e.target.value;
		if (value !== '') {
			value = parseFloat(value);
			if (value < 0) {
				value = 0;
			} else if (value > 10) {
				value = 10;
			}
		}
		setFixedStoploss(value);
	};

	return (
		<div className="fixed left-1/2 -translate-x-1/2 text-lg inset-0 z-50 bg-black-30">
			<fieldset>
				<legend className="font-bold pt-6 mb-2 border-b w-full">Global Simulation Params:</legend>
				<div className="flex justify-between items-center">
					<label
						htmlFor={`${indicatorPrefix}prevent-enter-when-pricechange-is-beyond`}
						className="flex justify-between items-center whitespace-nowrap cursor-pointer"
					>
						<input
							type="checkbox"
							className="mr-2"
							id={`${indicatorPrefix}prevent-enter-when-pricechange-is-beyond`}
							checked={!!forSimulation.preventEnterWhenPricechangeIsBeyond}
							onChange={switchCheckbox.bind(null, 'preventEnterWhenPricechangeIsBeyond')}
						/>
						Prevent enter when pricechange % is beyond
					</label>
					<input
						type="text"
						onChange={handleChangePreventEnterWhenPricechangeIsBeyondMin}
						value={boundaries.preventEnterWhenPricechangeIsBeyond.min}
						className="form-input ml-2 w-20 rounded border border-indigo-300 bg-transparent px-4 py-1 text-right text-white placeholder:text-white/25"
					/>
					<input
						type="text"
						onChange={handleChangePreventEnterWhenPricechangeIsBeyondMax}
						value={boundaries.preventEnterWhenPricechangeIsBeyond.max}
						className="form-input ml-2 w-20 rounded border border-indigo-300 bg-transparent px-4 py-1 text-right text-white placeholder:text-white/25"
					/>
					<input
						type="text"
						onChange={handleChangePreventEnterWhenPricechangeIsBeyondStep}
						value={boundaries.preventEnterWhenPricechangeIsBeyond.step}
						className="form-input ml-2 w-20 rounded border border-indigo-300 bg-transparent px-4 py-1 text-right text-white placeholder:text-white/25"
					/>
					<input
						type="text"
						onChange={handleChangePreventEnterOnExtremePriceAreaMin}
						value={boundaries.preventEnterOnExtremePriceArea.min}
						className="form-input ml-2 w-20 rounded border border-indigo-300 bg-transparent px-4 py-1 text-right text-white placeholder:text-white/25"
					/>
					<input
						type="text"
						onChange={handleChangePreventEnterOnExtremePriceAreaMax}
						value={boundaries.preventEnterOnExtremePriceArea.max}
						className="form-input ml-2 w-20 rounded border border-indigo-300 bg-transparent px-4 py-1 text-right text-white placeholder:text-white/25"
					/>
					<input
						type="text"
						onChange={handleChangePreventEnterOnExtremePriceAreaStep}
						value={boundaries.preventEnterOnExtremePriceArea.step}
						className="form-input ml-2 w-20 rounded border border-indigo-300 bg-transparent px-4 py-1 text-right text-white placeholder:text-white/25"
					/>
				</div>

				<div className="flex justify-between items-center">
					<label
						htmlFor={`${indicatorPrefix}prevent-enter-within-candles-after-close-previous-position`}
						className="flex justify-between items-center whitespace-nowrap cursor-pointer"
					>
						<input
							type="checkbox"
							className="mr-2"
							id={`${indicatorPrefix}prevent-enter-within-candles-after-close-previous-position`}
							checked={!!forSimulation.preventEnterWithinCandlesAfterClosePreviousPosition}
							onChange={switchCheckbox.bind(null, 'preventEnterWithinCandlesAfterClosePreviousPosition')}
						/>
						Prevent enter within #candles after close previous position
					</label>
					<input
						type="text"
						onChange={handleChangePreventEnterWithinCandlesAfterClosePreviousPositionMin}
						value={boundaries.preventEnterWithinCandlesAfterClosePreviousPosition.min}
						className="form-input ml-2 w-20 rounded border border-indigo-300 bg-transparent px-4 py-1 text-right text-white placeholder:text-white/25"
					/>
					<input
						type="text"
						onChange={handleChangePreventEnterWithinCandlesAfterClosePreviousPositionMax}
						value={boundaries.preventEnterWithinCandlesAfterClosePreviousPosition.max}
						className="form-input ml-2 w-20 rounded border border-indigo-300 bg-transparent px-4 py-1 text-right text-white placeholder:text-white/25"
					/>
					<input
						type="text"
						onChange={handleChangePreventEnterWithinCandlesAfterClosePreviousPositionStep}
						value={boundaries.preventEnterWithinCandlesAfterClosePreviousPosition.step}
						className="form-input ml-2 w-20 rounded border border-indigo-300 bg-transparent px-4 py-1 text-right text-white placeholder:text-white/25"
					/>
				</div>

				<div className="flex justify-between items-center">
					<label
						htmlFor={`${indicatorPrefix}stoploss`}
						className="flex justify-between items-center whitespace-nowrap cursor-pointer"
					>
						<input
							type="checkbox"
							className="mr-2"
							id={`${indicatorPrefix}stoploss`}
							checked={!!forSimulation.stoploss}
							onChange={switchCheckbox.bind(null, 'stoploss')}
						/>
						Stoploss % (real price)
					</label>
					<input
						type="text"
						onChange={handleChangeStoplossMin}
						value={boundaries.stoploss.min}
						className="form-input ml-2 w-20 rounded border border-indigo-300 bg-transparent px-4 py-1 text-right text-white placeholder:text-white/25"
					/>
					<input
						type="text"
						onChange={handleChangeStoplossMax}
						value={boundaries.stoploss.max}
						className="form-input ml-2 w-20 rounded border border-indigo-300 bg-transparent px-4 py-1 text-right text-white placeholder:text-white/25"
					/>
					<input
						type="text"
						onChange={handleChangeStoplossStep}
						value={boundaries.stoploss.step}
						className="form-input ml-2 w-20 rounded border border-indigo-300 bg-transparent px-4 py-1 text-right text-white placeholder:text-white/25"
					/>
				</div>

				<div className="flex justify-between items-center">
					<label
						htmlFor={`${indicatorPrefix}close-on-immediate-consecutive-nr-candles-reversal`}
						className="flex justify-between items-center whitespace-nowrap cursor-pointer"
					>
						<input
							type="checkbox"
							className="mr-2"
							id={`${indicatorPrefix}close-on-immediate-consecutive-nr-candles-reversal`}
							checked={!!forSimulation.closeOnImmediateConsecutiveNrCandlesReversal}
							onChange={switchCheckbox.bind(null, 'closeOnImmediateConsecutiveNrCandlesReversal')}
						/>
						Close on immediate consecutive #candles reversal
					</label>
					<div>
						<input
							type="text"
							onChange={handleChangeCloseOnImmediateConsecutiveNrCandlesReversalMin}
							value={boundaries.closeOnImmediateConsecutiveNrCandlesReversal.min}
							className="form-input ml-2 w-20 rounded border border-indigo-300 bg-transparent px-4 py-1 text-right text-white placeholder:text-white/25"
						/>
						<input
							type="text"
							onChange={handleChangeCloseOnImmediateConsecutiveNrCandlesReversalMax}
							value={boundaries.closeOnImmediateConsecutiveNrCandlesReversal.max}
							className="form-input ml-2 w-20 rounded border border-indigo-300 bg-transparent px-4 py-1 text-right text-white placeholder:text-white/25"
						/>
						<input
							type="text"
							onChange={handleChangeCloseOnImmediateConsecutiveNrCandlesReversalStep}
							value={boundaries.closeOnImmediateConsecutiveNrCandlesReversal.step}
							className="form-input ml-2 w-20 rounded border border-indigo-300 bg-transparent px-4 py-1 text-right text-white placeholder:text-white/25"
						/>
						<input
							type="text"
							onChange={handleChangeCloseOnImmediateConsecutiveNrCandlesReversalAreaMin}
							value={boundaries.closeOnImmediateConsecutiveNrCandlesReversalArea.min}
							className="form-input ml-2 w-20 rounded border border-indigo-300 bg-transparent px-4 py-1 text-right text-white placeholder:text-white/25"
						/>
						<input
							type="text"
							onChange={handleChangeCloseOnImmediateConsecutiveNrCandlesReversalAreaMax}
							value={boundaries.closeOnImmediateConsecutiveNrCandlesReversalArea.max}
							className="form-input ml-2 w-20 rounded border border-indigo-300 bg-transparent px-4 py-1 text-right text-white placeholder:text-white/25"
						/>
						<input
							type="text"
							onChange={handleChangeCloseOnImmediateConsecutiveNrCandlesReversalAreaStep}
							value={boundaries.closeOnImmediateConsecutiveNrCandlesReversalArea.step}
							className="form-input ml-2 w-20 rounded border border-indigo-300 bg-transparent px-4 py-1 text-right text-white placeholder:text-white/25"
						/>
					</div>
				</div>

				<label htmlFor={`${indicatorPrefix}stoploss-only-on-two-sequent-candles`} className="block cursor-pointer">
					<input
						type="checkbox"
						className="mr-2"
						id={`${indicatorPrefix}stoploss-only-on-two-sequent-candles`}
						checked={!!forSimulation.stoplossOnlyOnTwoSequentCandles}
						onChange={switchCheckbox.bind(null, 'stoplossOnlyOnTwoSequentCandles')}
					/>
					Stoploss only on 2 sequent candles
				</label>

				<label
					htmlFor={`${indicatorPrefix}close-on-price-reversal-to-original-candle-open-price`}
					className="block cursor-pointer"
				>
					<input
						type="checkbox"
						className="mr-2"
						id={`${indicatorPrefix}close-on-price-reversal-to-original-candle-open-price`}
						checked={!!forSimulation.closeOnPriceReversalToOriginalCandleOpenPrice}
						onChange={switchCheckbox.bind(null, 'closeOnPriceReversalToOriginalCandleOpenPrice')}
					/>
					Close on price reversal to original Candle Open price (real price)
				</label>

				<div className="flex justify-between items-center">
					<label
						htmlFor={`${indicatorPrefix}use-profit-trailing-security`}
						className="flex justify-between items-center whitespace-nowrap cursor-pointer"
					>
						<input
							type="checkbox"
							className="mr-2"
							id={`${indicatorPrefix}use-profit-trailing-security`}
							checked={!!forSimulation.useProfitTrailingSecurity}
							onChange={switchCheckbox.bind(null, 'useProfitTrailingSecurity')}
						/>
						Use Profit Trailing security
					</label>
					<div>
						<input
							type="text"
							onChange={handleChangeUseProfitTrailingSecurityActivatedAtPriceMoveMin}
							value={boundaries.useProfitTrailingSecurityActivatedAtPriceMove.min}
							className="form-input ml-2 w-20 rounded border border-indigo-300 bg-transparent px-4 py-1 text-right text-white placeholder:text-white/25"
						/>
						<input
							type="text"
							onChange={handleChangeUseProfitTrailingSecurityActivatedAtPriceMoveMax}
							value={boundaries.useProfitTrailingSecurityActivatedAtPriceMove.max}
							className="form-input ml-2 w-20 rounded border border-indigo-300 bg-transparent px-4 py-1 text-right text-white placeholder:text-white/25"
						/>
						<input
							type="text"
							onChange={handleChangeUseProfitTrailingSecurityActivatedAtPriceMoveStep}
							value={boundaries.useProfitTrailingSecurityActivatedAtPriceMove.step}
							className="form-input ml-2 w-20 rounded border border-indigo-300 bg-transparent px-4 py-1 text-right text-white placeholder:text-white/25"
						/>
					</div>
					<div>
						<input
							type="text"
							onChange={handleChangeUseProfitTrailingSecurityTakeTrailingProfitMin}
							value={boundaries.useProfitTrailingSecurityTakeTrailingProfit.min}
							className="form-input ml-2 w-20 rounded border border-indigo-300 bg-transparent px-4 py-1 text-right text-white placeholder:text-white/25"
						/>
						<input
							type="text"
							onChange={handleChangeUseProfitTrailingSecurityTakeTrailingProfitMax}
							value={boundaries.useProfitTrailingSecurityTakeTrailingProfit.max}
							className="form-input ml-2 w-20 rounded border border-indigo-300 bg-transparent px-4 py-1 text-right text-white placeholder:text-white/25"
						/>
						<input
							type="text"
							onChange={handleChangeUseProfitTrailingSecurityTakeTrailingProfitStep}
							value={boundaries.useProfitTrailingSecurityTakeTrailingProfit.step}
							className="form-input ml-2 w-20 rounded border border-indigo-300 bg-transparent px-4 py-1 text-right text-white placeholder:text-white/25"
						/>
					</div>
				</div>

				<label htmlFor={`${indicatorPrefix}prevent-close-on-profit-candle`} className="block cursor-pointer">
					<input
						type="checkbox"
						className="mr-2"
						id={`${indicatorPrefix}prevent-close-on-profit-candle`}
						checked={!!forSimulation.preventCloseOnProfitCandle}
						onChange={switchCheckbox.bind(null, 'preventCloseOnProfitCandle')}
					/>
					Prevent close when candle is in trade-direction (profit trailing)
				</label>

				<div className="flex justify-between items-center">
					<label
						htmlFor={`${indicatorPrefix}prevent-close-within-first-nr-candles`}
						className="flex justify-between items-center whitespace-nowrap cursor-pointer"
					>
						<input
							type="checkbox"
							className="mr-2"
							id={`${indicatorPrefix}prevent-close-within-first-nr-candles`}
							checked={!!forSimulation.preventCloseWithinFirstNrCandles}
							onChange={switchCheckbox.bind(null, 'preventCloseWithinFirstNrCandles')}
						/>
						Prevent close within first #candles
					</label>
					<input
						type="text"
						onChange={handleChangePreventCloseWithinFirstNrCandlesMin}
						value={boundaries.preventCloseWithinFirstNrCandles.min}
						className="form-input ml-2 w-20 rounded border border-indigo-300 bg-transparent px-4 py-1 text-right text-white placeholder:text-white/25"
					/>
					<input
						type="text"
						onChange={handleChangePreventCloseWithinFirstNrCandlesMax}
						value={boundaries.preventCloseWithinFirstNrCandles.max}
						className="form-input ml-2 w-20 rounded border border-indigo-300 bg-transparent px-4 py-1 text-right text-white placeholder:text-white/25"
					/>
					<input
						type="text"
						onChange={handleChangePreventCloseWithinFirstNrCandlesStep}
						value={boundaries.preventCloseWithinFirstNrCandles.step}
						className="form-input ml-2 w-20 rounded border border-indigo-300 bg-transparent px-4 py-1 text-right text-white placeholder:text-white/25"
					/>
				</div>

				<label htmlFor={`${indicatorPrefix}prevent-close-when-price-lost`} className="block cursor-pointer">
					<input
						type="checkbox"
						className="mr-2"
						id={`${indicatorPrefix}prevent-close-when-price-lost`}
						checked={!!forSimulation.preventCloseWhenPriceLost}
						onChange={switchCheckbox.bind(null, 'preventCloseWhenPriceLost')}
					/>
					Prevent close when price lost (candles)
				</label>

				<label htmlFor={`${indicatorPrefix}min-profit`} className="block cursor-pointer">
					<input
						type="checkbox"
						className="mr-2"
						id={`${indicatorPrefix}min-profit`}
						checked={!!forSimulation.minProfit}
						onChange={switchCheckbox.bind(null, 'minProfit')}
					/>
					Minimum Profit when Prevent close when price lost
					<input
						type="text"
						onChange={handleChangeMinProfitMin}
						value={boundaries.minProfit.min}
						className="form-input ml-2 w-20 rounded border border-indigo-300 bg-transparent px-4 py-1 text-right text-white placeholder:text-white/25"
					/>
					<input
						type="text"
						onChange={handleChangeMinProfitMax}
						value={boundaries.minProfit.max}
						className="form-input ml-2 w-20 rounded border border-indigo-300 bg-transparent px-4 py-1 text-right text-white placeholder:text-white/25"
					/>
					<input
						type="text"
						onChange={handleChangeMinProfitStep}
						value={boundaries.minProfit.step}
						className="form-input ml-2 w-20 rounded border border-indigo-300 bg-transparent px-4 py-1 text-right text-white placeholder:text-white/25"
					/>
				</label>

				<div className="flex justify-between items-center">
					<label
						htmlFor={`${indicatorPrefix}prevent-close-within-first-nr-candles-only-during-nr-candles`}
						className="flex justify-between items-center whitespace-nowrap cursor-pointer"
					>
						<input
							type="checkbox"
							className="mr-2"
							id={`${indicatorPrefix}prevent-close-within-first-nr-candles-only-during-nr-candles`}
							checked={!!forSimulation.preventCloseWithinFirstNrCandlesOnlyDuringNrCandles}
							onChange={switchCheckbox.bind(null, 'preventCloseWithinFirstNrCandlesOnlyDuringNrCandles')}
						/>
						Prevent close within first #candles ONLY during #candles
					</label>
					<input
						type="text"
						onChange={handleChangePreventCloseWithinFirstNrCandlesOnlyDuringNrCandlesMin}
						value={boundaries.preventCloseWithinFirstNrCandlesOnlyDuringNrCandles.min}
						className="form-input ml-2 w-20 rounded border border-indigo-300 bg-transparent px-4 py-1 text-right text-white placeholder:text-white/25"
					/>
					<input
						type="text"
						onChange={handleChangePreventCloseWithinFirstNrCandlesOnlyDuringNrCandlesMax}
						value={boundaries.preventCloseWithinFirstNrCandlesOnlyDuringNrCandles.max}
						className="form-input ml-2 w-20 rounded border border-indigo-300 bg-transparent px-4 py-1 text-right text-white placeholder:text-white/25"
					/>
					<input
						type="text"
						onChange={handlePreventCloseWithinFirstNrCandlesOnlyDuringNrCandlesStep}
						value={boundaries.preventCloseWithinFirstNrCandlesOnlyDuringNrCandles.step}
						className="form-input ml-2 w-20 rounded border border-indigo-300 bg-transparent px-4 py-1 text-right text-white placeholder:text-white/25"
					/>
				</div>

				<label
					htmlFor={`${indicatorPrefix}prevent-close-on-position-in-other-direction`}
					className="block cursor-pointer"
				>
					<input
						type="checkbox"
						className="mr-2"
						id={`${indicatorPrefix}prevent-close-on-position-in-other-direction`}
						checked={!!forSimulation.preventCloseOnPositionInOtherDirection}
						onChange={switchCheckbox.bind(null, 'preventCloseOnPositionInOtherDirection')}
					/>
					Prevent close on position in other direction
				</label>
			</fieldset>
			<fieldset className="flex font-bold pt-6 pb-6 mb-6 border-t border-b w-full">
				<label
					htmlFor={`${indicatorPrefix}run-simulation-with-stoploss`}
					className="flex justify-between items-center whitespace-nowrap cursor-pointer"
				>
					Run Simulation with stoploss
				</label>
				<input
					type="text"
					id={`${indicatorPrefix}run-simulation-with-stoploss`}
					onChange={handleChangeFixedStoploss}
					value={fixedStoploss}
					className="form-input ml-2 w-20 rounded border border-indigo-300 bg-transparent px-4 py-1 text-right text-white placeholder:text-white/25"
				/>
			</fieldset>
			<fieldset>
				<button
					className="text-white rounded px-4 py-2 bg-yellow-900 hover:bg-yellow-600"
					type="button"
					onClick={onClose}
				>
					cancel
				</button>
				<button
					className="text-white rounded px-4 py-2 bg-yellow-900 hover:bg-yellow-600"
					type="button"
					onClick={handleSimulate}
				>
					start simulation
				</button>
			</fieldset>
		</div>
	);
};

GlobalSimulationParams.defaultProps = {
	configBoundaries: {
		preventEnterWhenPricechangeIsBeyond: {
			type: 'number',
			min: 0.05,
			max: 1,
			step: 0.05,
		},
		preventEnterOnExtremePriceArea: {
			type: 'number',
			min: 1,
			max: 5,
			step: 1,
		},
		preventEnterWithinCandlesAfterClosePreviousPosition: {
			type: 'number',
			min: 5,
			max: 50,
			step: 1,
		},
		stoploss: {
			type: 'number',
			min: 0.5,
			max: 2,
			step: 0.1,
		},
		closeOnImmediateConsecutiveNrCandlesReversal: {
			type: 'number',
			min: 3,
			max: 8,
			step: 1,
		},
		closeOnImmediateConsecutiveNrCandlesReversalArea: {
			type: 'number',
			min: 0,
			max: 20,
			step: 1,
		},
		stoplossOnlyOnTwoSequentCandles: 'boolean',
		closeOnPriceReversalToOriginalCandleOpenPrice: 'boolean',
		useProfitTrailingSecurityActivatedAtPriceMove: {
			type: 'number',
			min: 0.5,
			max: 2,
			step: 0.1,
		},
		useProfitTrailingSecurityTakeTrailingProfit: {
			type: 'number',
			min: 0,
			max: 40,
			step: 5,
		},
		preventCloseWithinFirstNrCandles: {
			type: 'number',
			min: 1,
			max: 10,
			step: 1,
		},
		preventCloseWhenPriceLost: 'boolean',
		preventCloseOnProfitCandle: 'boolean',
		preventCloseWithinFirstNrCandlesOnlyDuringNrCandles: {
			type: 'number',
			min: 20,
			max: 100,
			step: 5,
		},
		minProfit: {
			type: 'number',
			min: 0,
			max: 5,
			step: 0.1,
		},
		preventCloseOnPositionInOtherDirection: 'boolean',
	},
};

GlobalSimulationParams.propTypes = {
	onClose: PropTypes.func.isRequired,
	onStartSimulation: PropTypes.func.isRequired,
};

export default GlobalSimulationParams;
