import React, { useContext, useEffect, useRef } from 'react';

import AlertModal from 'components/common/modals/AlertModal';
import feedbackCtx from 'context/feedback';
import managedPromise from 'lib/managedPromise';
/*
const ICONS = {
	error: CancelIcon,
	success: CheckCircleIcon,
	warning: WarningIcon,
	info: InfoIcon,
};
*/
const useAlert = () => {
	const { add, remove } = useContext(feedbackCtx);
	const promises = useRef([]);

	useEffect(() => {
		return () => {
			// eslint-disable-next-line react-hooks/exhaustive-deps
			promises.current.forEach(managedPromise => {
				if (managedPromise.isPending()) {
					managedPromise.close();
				}
			});
		};
	}, []);

	const cleanup = promise => {
		const index = promises.current.indexOf(promise);
		if (index !== -1) {
			promises.current.splice(index, 1);
		}
	};

	/**
	 * Shows an alert modal with an ok button.
	 * @param {string|Node} message Modal content
	 * @param {string=} title Modal title
	 * @param {('success'|'error'|'warning'|'info')=} severity Modal severity
	 * @param {(() => Promise<void>)=} callback Async callback method which displays loading until complete
	 */
	const alert = (message, severityOrOptions = '') => {
		const promise = managedPromise();
		if (!message) {
			promise.fulfill();
			return promise;
		}

		let id;

		if (typeof severityOrOptions === 'string') {
			severityOrOptions = {
				severity: severityOrOptions,
			};
		}
		if (!severityOrOptions.severity) {
			severityOrOptions.severity = 'info';
		}

		const onClose = byPromise => {
			if (typeof byPromise !== 'boolean') {
				byPromise = false;
			}
			remove(id);
			promise.fulfill();
			cleanup(promise);
		};

		const icon = null; // ICONS[severityOrOptions.severity];

		const handleClose = onClose;

		const content = (
			<AlertModal message={message} severity={severityOrOptions.severity} icon={icon} onClose={handleClose} />
		);

		id = add(
			{
				content,
				onClose,
				timeout: severityOrOptions.timeout,
				backdrop: false,
			},
			severityOrOptions,
		);

		const closedByPromise = () => {
			onClose(true);
		};

		promise.close = closedByPromise;
		promises.current.push(promise);

		return promise;
	};

	return alert;
};

export default useAlert;
