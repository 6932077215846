import expandBnDecimals from 'lib/expand-BN-decimals';
import { safeFormatBN } from 'lib/formatBN';

const floatToText = (value, { decimals = 2, minDigits, append, prepend }) => {
	if (typeof value !== 'number' || value === '' || isNaN(value) || value === Infinity || value === -Infinity) {
		return '';
	}
	const assetDigits = 10;
	return safeFormatBN(expandBnDecimals(value, assetDigits), { assetDigits, minDigits, decimals }, prepend, append);
};

export default floatToText;
