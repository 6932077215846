import React, { useMemo, useEffect } from 'react';
import createStore from 'ctx-provider';

import useQueue from 'hooks/useQueue';

const useFeedback = () => {
	const { add, remove, first, empty } = useQueue();

	useEffect(() => {
		return () => {
			empty();
		};
	}, []);

	const handleClose = (/* event, reason */) => {
		first.onClose();
	};

	const action = (
		<div size="small" aria-label="close" color="inherit" onClick={handleClose}>
			<button fontSize="small" />
		</div>
	);

	const feedbackMsg = useMemo(() => {
		if (first) {
			const { content, timeout = 8000 } = first;
			let duration;
			if (timeout !== 0) {
				duration = timeout;
			}
			return (
				<div
					open
					// autoHideDuration={duration}
					onClose={handleClose}
					// anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
					action={action}
				>
					{content}
				</div>
			);
		}
		return null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [first]);

	const feedbackHasBackdrop = useMemo(() => {
		return first ? !!first.backdrop : false;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [first]);

	return { feedbackMsg, feedbackHasBackdrop, add, remove };
};

const store = createStore(useFeedback);

export const { Provider } = store;
export default store.ctx;
