import React from 'react';
import PropTypes from 'prop-types';

const YesNoButton = ({ classNameContainer, classNameNoButton, classNameYesButton, onYes, onNo }) => {
	return (
		<div className={classNameContainer}>
			<button className={classNameNoButton} onClick={onNo}>
				No
			</button>
			<button className={classNameYesButton} onClick={onYes}>
				Yes
			</button>
		</div>
	);
};

YesNoButton.defaultProps = {
	classNameContainer: 'flex flex-col-reverse w-full sm:flex-row sm:w-auto',
	classNameYesButton: 'bg-blue-400 text-white sm:ml-2 sm:w-auto w-[90vw] px-4 py-2',
	classNameNoButton: 'bg-blue-400 text-white sm:ml-2 sm:w-auto w-[90vw] px-4 py-2',
};

YesNoButton.propTypes = {
	classNameContainer: PropTypes.string,
	classNameYesButton: PropTypes.string,
	classNameNoButton: PropTypes.string,
	onNo: PropTypes.func.isRequired,
	onYes: PropTypes.func.isRequired,
};

export default YesNoButton;
