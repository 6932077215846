import { useEffect, useState, useRef, useContext } from 'react';
import createStore from 'ctx-provider';
import { cloneDeep } from 'lodash';
import tradesCtx from 'context/trades';
import authenticationCtx from 'context/authentication';
import exchangeCtx from 'context/exchange';
import useAlert from 'hooks/useAlert';
import { getBalances } from 'api/trading-api';
import { later } from 'lib/timers';

const REFRESH_TIME_MS = 5000;

const transformPhemexData = record => {
	return {
		symbol: record.currency,
		balance: parseFloat(record.accountBalanceRv),
		exchange: 'phemex',
	};
};

const useBalances = () => {
	const { positions, subscribePositions, positionsLoaded } = useContext(tradesCtx);
	const alert = useAlert();
	const { isLoggedIn } = useContext(authenticationCtx);
	const { exchange } = useContext(exchangeCtx);
	const [balances, setBalances] = useState([]);
	const exchangeRef = useRef(exchange);
	const timeoutRef = useRef();

	const loadData = async () => {
		if (isLoggedIn && exchangeRef.current) {
			try {
				const exchange = exchangeRef.current.name;
				const network = exchangeRef.current.network;
				const symbol = exchangeRef.current.baseToken;
				const data = [];
				const payload = {
					exchange,
					network,
					symbol,
				};

				if (exchange === 'dydx') {
					delete payload.symbol; // doesn't work in tthe testnet
				}
				let exchangeData = await getBalances(payload);

				// only apply is the exchange did not change:
				if (exchange === exchangeRef.current.name && network === exchangeRef.current.network && exchangeData) {
					if (exchange === 'dydx') {
						const balance = parseFloat(exchangeData?.equity || 0);
						const balanceObject = {
							exchange,
							network,
							balance,
						};
						data.push(balanceObject);
					} else if (exchange === 'phemex') {
						const dataPhemexContract = exchangeData.map(transformPhemexData);
						const phemexBalance = dataPhemexContract[0];

						if (phemexBalance) {
							let balance = phemexBalance.balance;
							positions.forEach(position => (balance += position.profit));

							const balanceObject = {
								exchange,
								network,
								balance,
							};

							Array.prototype.push.call(data, balanceObject);
						}
					}

					await setBalances(cloneDeep(data));
				}
			} catch (err) {
				console.error(err);
				const message = err.response?.data?.error || err.message || 'Server error';
				// send an alert, but prevent alerting multiple times, by marking this message with "match":
				alert(message, { noDupes: true, match: `${message}#error`, severity: 'error' });
			}
		}
	};

	const cancelTimeout = () => {
		if (timeoutRef.current) {
			timeoutRef.current.cancel();
		}
	};

	const refreshBalances = () => {
		if (isLoggedIn && exchangeRef.current && positionsLoaded) {
			cancelTimeout();
			loadData();
			timeoutRef.current = later(refreshBalances, REFRESH_TIME_MS);
		}
	};

	useEffect(() => {
		subscribePositions(); // we do not unsubscribe -> that will be handled by the main app
		refreshBalances();
	}, []);

	useEffect(() => {
		exchangeRef.current = exchange;
	}, [exchange]);

	useEffect(() => {
		refreshBalances();
	}, [isLoggedIn, positions]);

	useEffect(() => {
		setBalances([]);
		refreshBalances();
	}, [positionsLoaded]);

	return {
		balances,
		refreshBalances,
	};
};

const store = createStore(useBalances);

export const { Provider } = store;
export default store.ctx;
