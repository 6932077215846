import axios from 'axios';
import { TRADING_API } from '../config/constants'; // for some reason, during "yarn build", 'config' does not resolve, whereas '../config' does

const mapCandlePricesToProperties = item => {
	return {
		Date: 1000 * item[0],
		Low: item[1],
		High: item[2],
		Open: item[3],
		Close: item[4],
		Volume: item[5],
	};
};

const apiRequest = async (uri, params = {}, returnId) => {
	let data;
	try {
		if (params.exchange) {
			params.exchange = params.exchange.toLowerCase();
		}
		const response = await axios.post(`${TRADING_API}/${uri}`, params, {
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
			},
			withCredentials: true, // make sure to send the cookies along
		});
		if (!response || response.status !== 200 || !response.data) {
			const err = `Error: ${response.status}: ${response.statusText}`;
			// throw new Error(err);
			console.error(err);
		}

		if (uri === 'logout') {
			data = response.data.success;
		} else if (returnId) {
			data = response.data?.id || response.data?.data?.id;
		} else {
			data = response.data?.data;
		}
	} catch (err) {
		console.error(err);
	}

	return data;
};

export const getPrice = async ({ symbol }) => apiRequest('get-price', { symbol });

export const getBalances = async ({ exchange, network, type, symbol }) =>
	apiRequest('get-balances', { exchange, network, type, symbol });

export const getCandlePrices = async ({ asset, interval, count, from }) => {
	const candlePrices = await apiRequest('candleprices', { asset, interval, count, from });
	return candlePrices.map(mapCandlePricesToProperties);
};

export const getPositions = async ({ exchange, network, type, onlyFromDatabase, force }) =>
	apiRequest('get-positions', { exchange, network, type, onlyFromDatabase, force });

export const getClosedPositions = async ({ exchange, network, type, symbol, limit, offset }) =>
	apiRequest('get-closed-positions', { exchange, network, type, symbol, limit, offset });

export const getOpenOrders = async ({ exchange, network, type, symbol }) =>
	apiRequest('get-open-orders', { exchange, network, type, symbol });

export const getStops = async ({ exchange, network, type }) => apiRequest('get-stops', { exchange, network, type });

export const getTradesHistory = async ({ exchange, network, symbol, limit, offset }) =>
	apiRequest('trades-history', { exchange, network, symbol, limit, offset });

export const getScope = async ({ account, password }) => {
	const data = await apiRequest('authenticate', { account, password });
	return data?.scope;
};

export const getNewTradeStats = async ({ exchange, symbol, amountWholeUSDC, leverage, isLong, useCache }) => {
	const data = await apiRequest('get-new-trade-stats', {
		exchange: exchange.name,
		network: exchange.network,
		symbol,
		isLong,
		amountWholeUSDC,
		leverage,
		useCache,
	});

	if (data?.amountInUSD) {
		data.amountInUSD = BigInt(data.amountInUSD);
		data.amountOutUSD = BigInt(data.amountOutUSD);
		data.lentUSD = BigInt(data.lentUSD);
		data.amountOut = BigInt(data.amountOut);
		data.entryPriceUSD = BigInt(data.entryPriceUSD);
		data.breakEvenPriceUSD = BigInt(data.breakEvenPriceUSD);
		data.liquidationPriceUSD = BigInt(data.liquidationPriceUSD);
		data.feeUSD = BigInt(data.feeUSD);

		if (data.stopLossPriceUSD) {
			data.stopLossPriceUSD = BigInt(data.stopLossPriceUSD);
		}
		if (data.stopLossesUSD) {
			data.stopLossesUSD = BigInt(data.stopLossesUSD);
		}
		if (data.takeProfitPriceUSD) {
			data.takeProfitPriceUSD = BigInt(data.takeProfitPriceUSD);
			data.takeProfitUSD = BigInt(data.takeProfitUSD);
		}
		data.hourlyInterestUSD = BigInt(data.hourlyInterestUSD);
		data.liquidity = BigInt(data.liquidity);
	}

	return data;
};

//=======================================================================================

export const closeMarginLong = async ({ exchange, network, marginModel, symbol, price }) =>
	apiRequest('close-margin-long', { exchange, network, marginModel, symbol, price });

export const closeMarginShort = async ({ exchange, network, marginModel, symbol, price }) =>
	apiRequest('close-margin-short', { exchange, network, marginModel, symbol, price });

export const marginLong = async ({
	exchange,
	network,
	marginModel,
	symbol,
	amountWholeUSDC,
	leverage,
	price,
	takeProfitAtUsd,
	stoploss,
	isTriggerOrder,
	takeProfitAtUsdLimitPrice,
	stoplossLimitPrice,
	retainOpenOrderTime,
}) =>
	apiRequest('margin-long', {
		exchange,
		marginModel,
		network,
		symbol,
		amountWholeUSDC,
		leverage,
		price,
		takeProfitAtUsd,
		stoploss,
		isTriggerOrder,
		takeProfitAtUsdLimitPrice,
		stoplossLimitPrice,
		retainOpenOrderTime,
	});

export const marginShort = async ({
	exchange,
	network,
	marginModel,
	symbol,
	amountWholeUSDC,
	leverage,
	price,
	takeProfitAtUsd,
	stoploss,
	isTriggerOrder,
	takeProfitAtUsdLimitPrice,
	stoplossLimitPrice,
	retainOpenOrderTime,
}) =>
	apiRequest('margin-short', {
		exchange,
		marginModel,
		network,
		symbol,
		amountWholeUSDC,
		leverage,
		price,
		takeProfitAtUsd,
		stoploss,
		isTriggerOrder,
		takeProfitAtUsdLimitPrice,
		stoplossLimitPrice,
		retainOpenOrderTime,
	});

export const canCancelOrder = async ({ exchange, network, action, symbol }) =>
	apiRequest('can-cancel-action', { exchange, network, action, symbol });

export const cancelOrder = async ({ exchange, network, orderid, symbol }) =>
	apiRequest('cancel-action', { exchange, network, orderid, symbol });

export const setStopLossLong = async ({ exchange, network, stopPriceUsd, symbol, priceAvailable }) =>
	apiRequest(priceAvailable ? 'update-stop-loss-long' : 'set-stop-loss-long', {
		exchange,
		network,
		stopPriceUsd,
		symbol,
	});

export const setStopLossShort = async ({ exchange, network, stopPriceUsd, symbol, priceAvailable }) =>
	apiRequest(priceAvailable ? 'update-stop-loss-short' : 'set-stop-loss-short', {
		exchange,
		network,
		stopPriceUsd,
		symbol,
	});

export const setTakeProfitLong = async ({ exchange, network, stopPriceUsd, symbol, priceAvailable }) =>
	apiRequest(priceAvailable ? 'update-take-profit-long' : 'set-take-profit-long', {
		exchange,
		network,
		stopPriceUsd,
		symbol,
	});

export const setTakeProfitShort = async ({ exchange, network, stopPriceUsd, symbol, priceAvailable }) =>
	apiRequest(priceAvailable ? 'update-take-profit-short' : 'set-take-profit-short', {
		exchange,
		network,
		stopPriceUsd,
		symbol,
	});

export const getMarketdata = async ({ exchange, network }) =>
	apiRequest('get-marketdata', {
		exchange,
		network,
	});

export const swapTokens = async ({ exchange, network, fromSymbol, toSymbol, amountFloat }) =>
	apiRequest('swap-tokens', { exchange, network, fromSymbol, toSymbol, amountFloat });

export const getStrategies = async ({ active, showperformance, performancelimit } = {}) =>
	apiRequest('get-strategies', { active, showperformance, performancelimit });

export const updateStrategy = async ({
	id,
	name,
	asset,
	version,
	interval,
	strategyenter,
	strategyexit,
	stoplosspercent,
	preventcloseonprofitcandle,
	panicstrategy,
	panicstrategyvalue,
	preventclosewithinfirstcandles,
	preventcloseonpricelost,
	closeonimmediatereveralactivated,
	closeonimmediatereveral,
	closeonimmediatereveralarea,
	closeonpricereversal,
	prevententeronextremepricechange,
	prevententeronextremepricechangevalue,
	prevententeronextremepricearea,
	preventcloseonpositioninotherdirection,
	stoplossonlyontwosequentcandles,
	usetrailingprofit,
	trailingprofit,
	trailingprofitinitiation,
	optimumtradeamountpercent,
	notradeswithincandlesafterlasttrade,
	notradeswithincandlesafterlasttradevalue,
	preventcloseonpricelostperiod,
	preventcloseonpricelostperiodvalue,
	minprofit,
	force,
}) =>
	apiRequest('update-strategy', {
		id,
		name,
		asset,
		version,
		interval,
		strategyenter,
		strategyexit,
		stoplosspercent,
		preventcloseonprofitcandle,
		panicstrategy,
		panicstrategyvalue,
		preventclosewithinfirstcandles,
		preventcloseonpricelost,
		closeonimmediatereveralactivated,
		closeonimmediatereveral,
		closeonimmediatereveralarea,
		closeonpricereversal,
		prevententeronextremepricechange,
		prevententeronextremepricechangevalue,
		prevententeronextremepricearea,
		preventcloseonpositioninotherdirection,
		stoplossonlyontwosequentcandles,
		usetrailingprofit,
		trailingprofit,
		trailingprofitinitiation,
		optimumtradeamountpercent,
		notradeswithincandlesafterlasttrade,
		notradeswithincandlesafterlasttradevalue,
		preventcloseonpricelostperiod,
		preventcloseonpricelostperiodvalue,
		minprofit,
		force,
	});

export const updateStrategyName = async ({ id, name }) => apiRequest('update-strategy-name', { id, name });

export const createStrategy = async ({
	name,
	asset,
	version,
	interval,
	strategyenter,
	strategyexit,
	stoplosspercent,
	preventcloseonprofitcandle,
	panicstrategy,
	panicstrategyvalue,
	preventclosewithinfirstcandles,
	preventcloseonpricelost,
	closeonimmediatereveralactivated,
	closeonimmediatereveral,
	closeonimmediatereveralarea,
	closeonpricereversal,
	prevententeronextremepricechange,
	prevententeronextremepricechangevalue,
	prevententeronextremepricearea,
	preventcloseonpositioninotherdirection,
	stoplossonlyontwosequentcandles,
	usetrailingprofit,
	trailingprofit,
	trailingprofitinitiation,
	optimumtradeamountpercent,
	notradeswithincandlesafterlasttrade,
	notradeswithincandlesafterlasttradevalue,
	preventcloseonpricelostperiod,
	preventcloseonpricelostperiodvalue,
	minprofit,
}) =>
	apiRequest(
		'create-strategy',
		{
			name,
			asset,
			version,
			interval,
			strategyenter,
			strategyexit,
			stoplosspercent,
			preventcloseonprofitcandle,
			panicstrategy,
			panicstrategyvalue,
			preventclosewithinfirstcandles,
			preventcloseonpricelost,
			closeonimmediatereveralactivated,
			closeonimmediatereveral,
			closeonimmediatereveralarea,
			closeonpricereversal,
			prevententeronextremepricechange,
			prevententeronextremepricechangevalue,
			prevententeronextremepricearea,
			preventcloseonpositioninotherdirection,
			stoplossonlyontwosequentcandles,
			usetrailingprofit,
			trailingprofit,
			trailingprofitinitiation,
			optimumtradeamountpercent,
			notradeswithincandlesafterlasttrade,
			notradeswithincandlesafterlasttradevalue,
			preventcloseonpricelostperiod,
			preventcloseonpricelostperiodvalue,
			minprofit,
		},
		true,
	);

export const activateStrategy = async ({ id, active }) => apiRequest('activate-strategy', { id, active });

export const removeStrategy = async ({ strategyid }) => apiRequest('remove-strategy', { strategyid }, true);

//=======================================================================================

export const accountGetSessionUser = async () => apiRequest('account-get-session-user');

export const accountActivation = async ({ accounthash, active }) =>
	apiRequest('account-activation', { accounthash, active });

export const accountChangeExchangeKey = async ({ exchange, exchangeKeys }) =>
	apiRequest('account-change-exchange-key', { exchange, exchangeKeys });

export const accountChangeExchangeEnterPosition = async ({ exchange, exchangeEnterPosition }) =>
	apiRequest('account-change-exchange-enterposition', { exchange, exchangeEnterPosition });

export const accountChangeExchangeTradeSettings = async ({
	exchange,
	margintradereserves,
	ordertypetrades,
	ordertypestoploss,
	overbidlimittrades,
	overbidlimitstoploss,
	strategytradesresultcorrection,
}) =>
	apiRequest('account-change-exchange-settings', {
		exchange,
		margintradereserves,
		ordertypetrades,
		ordertypestoploss,
		overbidlimittrades,
		overbidlimitstoploss,
		strategytradesresultcorrection,
	});

export const accountChangePassword = async ({ oldPassword, newPassword }) =>
	apiRequest('account-change-password', { oldPassword, newPassword });

export const accountChangeUsername = async ({ accountname }) => apiRequest('account-change-username', { accountname });

export const accountChangeRole = async ({ accounthash, role }) =>
	apiRequest('account-change-role', { accounthash, role });

export const accountList = async () => apiRequest('account-list');

export const accountNew = async ({ accountname, password, role }) =>
	apiRequest('account-new', { accountname, password, role }, true);

export const accountRemoval = async ({ accounthash }) => apiRequest('account-removal', { accounthash }, true);

export const accountStrategyList = async ({ showperformance, performancelimit, exchange, network } = {}) =>
	apiRequest('account-strategy-list', { showperformance, performancelimit, exchange, network });

export const accountStrategyNew = async ({ strategyid, type, symbol, exchange, network, leverage, percentamount }) =>
	apiRequest(
		'account-strategy-new',
		{
			strategyid,
			type,
			symbol,
			exchange,
			network,
			leverage,
			percentamount,
		},
		true,
	);

export const accountStrategyChange = async ({
	accountstrategyid,
	strategyid,
	type,
	symbol,
	exchange,
	network,
	leverage,
	percentamount,
}) =>
	apiRequest('account-strategy-change', {
		accountstrategyid,
		strategyid,
		type,
		symbol,
		exchange,
		network,
		leverage,
		percentamount,
	});

export const accountStrategyActivation = async ({ accountstrategyid, active }) =>
	apiRequest('account-strategy-activation', { accountstrategyid, active });

export const accountStrategyRemoval = async ({ accountstrategyid }) =>
	apiRequest('account-strategy-removal', { accountstrategyid }, true);

export const getStrategySimulations = async ({ finished } = {}) => {
	const records = await apiRequest('get-strategy-simulations', { finished });
	records.forEach(record => {
		if (record.starttime) {
			record.starttime = new Date(record.starttime);
		}
		if (record.stoptime) {
			record.stoptime = new Date(record.stoptime);
		}
		if (record.eta) {
			record.eta = new Date(record.eta);
		}
	});
	return records;
};

export const getStrategySimulationDetails = async ({ strategysimulationid, limit }) =>
	apiRequest('get-strategy-simulation-details', { strategysimulationid, limit });

export const removeStrategySimulation = async ({ simulationid }) => apiRequest('remove-simulation', { simulationid });

export const restartStrategySimulation = async ({ simulationid }) => apiRequest('restart-simulation', { simulationid });

export const releaseSimulation = async ({ simulationid, processedby, force }) =>
	apiRequest('release-simulation', { simulationid, processedby, force });

export const defineStrategySimulation = async ({ strategyid, simulate, stoploss }) =>
	apiRequest('define-simulation', { strategyid, simulate, stoploss });

export const getSimulatorStatus = async () => apiRequest('get-simulator-status');

//=======================================================================================

export const ping = async () => {
	const data = await apiRequest('ping');
	return data?.scope;
};

export const logout = async () => {
	let response;
	try {
		response = await apiRequest('logout');
	} catch (err) {
		console.debug('ERROR LOGOUT', err);
	}
	return response;
};
