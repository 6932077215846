import React from 'react';
import PropTypes from 'prop-types';
import YesNoButton from 'components/common/YesNoButton';

import CancelIcon from 'assets/images/svg/cancel.svg';
import CheckCircleIcon from 'assets/images/svg/circle-check.svg';
import InfoIcon from 'assets/images/svg/info.svg';
import WarningIcon from 'assets/images/svg/warning.svg';
import QuestionIcon from 'assets/images/svg/question.svg';

const icons = {
	error: CancelIcon,
	info: InfoIcon,
	question: QuestionIcon,
	success: CheckCircleIcon,
	warning: WarningIcon,
};

const ConfirmDialog = props => {
	const {
		className,
		classNameCloseButton,
		classNameIcon,
		classNameYesNoContainer,
		classNameYesButton,
		classNameNoButton,
		message,
		onYes,
		onNo,
		onClose,
		severity,
	} = props;

	const icon = <span className={classNameIcon}>{icons[severity]}</span>;
	const action = (
		<YesNoButton
			classNameContainer={classNameYesNoContainer}
			classNameYesButton={classNameYesButton}
			classNameNoButton={classNameNoButton}
			onNo={onNo}
			onYes={onYes}
		/>
	);

	let closeicon;
	if (onClose) {
		closeicon = (
			<button className={classNameCloseButton} onClick={onClose}>
				x
			</button>
		);
	}

	return (
		<div className={className}>
			{icon}
			<span>{message}</span>
			{action}
			{closeicon}
		</div>
	);
};

ConfirmDialog.defaultProps = {
	className: '',
	classNameCloseButton: '',
	classNameIcon: '',
	severity: 'question',
};

ConfirmDialog.propTypes = {
	className: PropTypes.string,
	classNameCloseButton: PropTypes.string,
	classNameIcon: PropTypes.string,
	classNameYesNoContainer: PropTypes.string,
	classNameYesButton: PropTypes.string,
	classNameNoButton: PropTypes.string,
	handleClose: PropTypes.func,
	message: PropTypes.string.isRequired,
	onClose: PropTypes.func,
	onNo: PropTypes.func.isRequired,
	onYes: PropTypes.func.isRequired,
	severity: PropTypes.oneOf(['error', 'info', 'success', 'warning', 'question']),
};

export default ConfirmDialog;
