const isFloat = value => typeof value === 'number' && Math.round(value) !== value;

const expandBnDecimals = (anyValue, decimals) => {
	let numberBN;
	if (isFloat(anyValue)) {
		anyValue = anyValue.toString();
	}
	if (typeof anyValue === 'string') {
		if (anyValue === '0') {
			numberBN = BigInt(0);
		} else {
			anyValue = anyValue.replaceAll(',', '.');
			if (anyValue.indexOf('.') !== -1) {
				numberBN = BigInt(Math.round(parseFloat(anyValue.replaceAll(',', '.')) * 10 ** decimals));
			}
		}
	}

	if (anyValue === 0) {
		numberBN = BigInt(0);
	} else if (numberBN === undefined) {
		numberBN = BigInt(anyValue) * 10n ** BigInt(decimals);
	}
	return numberBN;
};

export default expandBnDecimals;
