import { useEffect, useState, useRef, useContext } from 'react';
import createStore from 'ctx-provider';
import { cloneDeep } from 'lodash';
import authenticationCtx from 'context/authentication';
import useAlert from 'hooks/useAlert';
import { getTradesHistory } from 'api/trading-api';
import { REFRESH_TIME_KRAKEN_MIN } from 'config/constants';
import exchangeCtx from 'context/exchange';

const REFRESH_TIME_KRAKEN_MS = REFRESH_TIME_KRAKEN_MIN * 60 * 1000;

const useTradesHistory = () => {
	const { exchange } = useContext(exchangeCtx);
	const alert = useAlert();
	const { isLoggedIn } = useContext(authenticationCtx);
	const [subscriptionActive, setSubscriptionActive] = useState(false);
	const [tradeHistory, setTradesHistory] = useState({});

	const timeoutId = useRef();
	const subscriptionActiveRef = useRef();

	const subscribe = () => {
		subscriptionActiveRef.current = true;
		setSubscriptionActive(true);
	};

	const unsubscribe = () => {
		subscriptionActiveRef.current = false;
		setSubscriptionActive(false);
	};

	const cancelTimeout = () => {
		if (timeoutId.current) {
			clearTimeout(timeoutId.current);
		}
	};

	const loadData = async () => {
		try {
			if (subscriptionActiveRef.current && exchange) {
				const data = await getTradesHistory({ exchange });
				setTradesHistory(cloneDeep(data));
			}
		} catch (err) {
			const message = err.response?.data?.error || err.message || 'Server error';
			// send an alert, but prevent alerting multiple times, by marking this message with "match":
			alert(message, { noDupes: true, match: `${message}#error`, severity: 'error' });
		}
		timeoutId.current = setTimeout(loadData, REFRESH_TIME_KRAKEN_MS);
	};

	useEffect(() => {
		return () => {
			cancelTimeout();
		};
	}, []);

	useEffect(() => {
		if (isLoggedIn && subscriptionActive) {
			loadData();
		} else {
			cancelTimeout();
			setTradesHistory({});
		}
	}, [isLoggedIn, subscriptionActive]);

	return {
		tradeHistory,
		subscribe,
		unsubscribe,
	};
};

const store = createStore(useTradesHistory);

export const { Provider } = store;
export default store.ctx;
