import React from 'react';
import PropTypes from 'prop-types';
import OkButton from 'components/common/OkButton';

import CancelIcon from 'assets/images/svg/cancel.svg';
import CheckCircleIcon from 'assets/images/svg/circle-check.svg';
import InfoIcon from 'assets/images/svg/info.svg';
import WarningIcon from 'assets/images/svg/warning.svg';
import QuestionIcon from 'assets/images/svg/question.svg';

const icons = {
	error: CancelIcon,
	info: InfoIcon,
	question: QuestionIcon,
	success: CheckCircleIcon,
	warning: WarningIcon,
};

const ConfirmDialog = props => {
	const { className, classNameIcon, classNameContainer, classNameButton, message, onOk, severity } = props;

	const icon = <span className={classNameIcon}>{icons[severity]}</span>;
	const action = <OkButton classNameContainer={classNameContainer} classNameButton={classNameButton} onOk={onOk} />;

	return (
		<div className={className}>
			{icon}
			{message}
			{action}
		</div>
	);
};

ConfirmDialog.defaultProps = {
	className: '',
	classNameIcon: '',
	severity: 'info',
};

ConfirmDialog.propTypes = {
	className: PropTypes.string,
	classNameCloseButton: PropTypes.string,
	classNameIcon: PropTypes.string,
	classNameYesNoContainer: PropTypes.string,
	classNameYesButton: PropTypes.string,
	classNameNoButton: PropTypes.string,
	handleClose: PropTypes.func,
	message: PropTypes.string.isRequired,
	onOk: PropTypes.func.isRequired,
	severity: PropTypes.oneOf(['error', 'info', 'success', 'warning', 'question']),
};

export default ConfirmDialog;
